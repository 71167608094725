export const getUnitStatuses = (units) => {

    const unitStatuses = {};

    let unitTotal = units.length;

    for (let u = 0; u < units.length; u++) {
        if (units[u].trailers.length) unitTotal = unitTotal + units[u].trailers.length;
    }

    unitStatuses.unitTotal = unitTotal;

    let totalSensorValues = 0;
    let redStatus = 0;
    let purpleStatus = 0;
    let blueStatus = 0;

    for (let unit = 0; unit < units.length; unit++) {
        totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].sensors.length);

        if (units[unit].linkedredFlags > 0) {
            redStatus = parseInt(redStatus) + parseInt(units[unit].linkedredFlags);
        }
        if (units[unit].linkedpurpleFlags > 0) {
            purpleStatus = parseInt(purpleStatus) + parseInt(units[unit].linkedpurpleFlags);
        }

        if (units[unit].linkedblueFlags > 0) {
            blueStatus = parseInt(blueStatus) + parseInt(units[unit].linkedblueFlags);
        }

        if (units[unit].trailers.length > 0) {
            for (let t = 0; t < units[unit].trailers.length; t++) {
                totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].trailers[t].sensors.length);
            }
        }
    }

    unitStatuses.totalSensorValues = totalSensorValues; 
    unitStatuses.greenStatus = parseInt(totalSensorValues) - parseInt((parseInt(redStatus) + parseInt(purpleStatus) + parseInt(blueStatus)));
    unitStatuses.redStatus = redStatus;
    unitStatuses.purpleStatus = purpleStatus;
    unitStatuses.blueStatus = blueStatus;

    unitStatuses.percCorrectPressure = Math.round(unitStatuses.greenStatus / totalSensorValues * 100);
    unitStatuses.percUnderInflated = Math.round(unitStatuses.redStatus / totalSensorValues * 100);
    unitStatuses.percOverInflated = Math.round(unitStatuses.purpleStatus / totalSensorValues * 100);
    unitStatuses.percNoSignal = Math.round(unitStatuses.blueStatus / totalSensorValues * 100);

    return unitStatuses;

}

export const getAxleTyreProperties = (unit) => {

    if (unit.sensors.length > 0) {

        for (let s = 0; s < unit.sensors.length; s++) {
            unit.sensors[s].wheelType = "wheelactive";
            unit.sensors[s].sensorType = "";
            unit.sensors[s].sensorSnipType = "";
            unit.sensors[s].tyreName = "A" + unit.sensors[s].sensorName.substring(0, 1) + "-T" + unit.sensors[s].sensorName.substring(1, 2);

            unit.sensors[s].pressureLabelColour = "green";
            unit.sensors[s].temperatureLabelColour = "green";
            unit.sensors[s].voltageLabelColour = "green";
            unit.sensors[s].noSignalLabelColour = "green";

            if (unit.sensors[s].pressureValue == null && unit.sensors[s].temperatureValue == null && unit.sensors[s].voltageValue == null) {
                unit.sensors[s].sensorType = "wheelblue";
                unit.sensors[s].sensorSnipType = "wheelsnipblue";
                unit.sensors[s].noSignalLabelColour = "blue";
            }

            if (parseFloat(unit.sensors[s].pressureValue) > parseFloat(unit.sensors[s].maxPressureValue)) {
                //assign flags here!!
                unit.sensors[s].sensorType = "wheelpurple";
                unit.sensors[s].sensorSnipType = "wheelsnippurple";
                unit.sensors[s].pressureLabelColour = "purple";
            }

            if (parseFloat(unit.sensors[s].pressureValue) < parseFloat(unit.sensors[s].minPressureValue)) {
                unit.sensors[s].sensorType = "wheelred";
                //let xleSensorPressureMetricAttributes = getAxleSensorPressureMetricAttributes(unitId, axle)
                if (unit.sensors[s].sensorName.slice(0, 1) !== "9" && (parseFloat(unit.sensors[s].pressureValue) < parseFloat(unit.sensors[s].manufacturersRecommendedPressure) * 20/100)) {
                    unit.sensors[s].sensorType = "wheelblink"; 
                }
                unit.sensors[s].sensorSnipType = "wheelsnipred";
                unit.sensors[s].pressureLabelColour = "red";
            }

            if (parseInt(unit.sensors[s].temperatureValue) > parseInt(unit.sensors[s].maxTemperatureValue)) {
                if (unit.sensors[s].sensorType !== "wheelpurple" && unit.sensors[s].sensorType !== "wheelred") {
                    unit.sensors[s].sensorType = "wheelorange";
                    unit.sensors[s].sensorSnipType = "wheelsniporange";
                }
                unit.sensors[s].temperatureLabelColour = "orange";
            }

            if (parseFloat(unit.sensors[s].voltageValue) < parseFloat(unit.sensors[s].minVoltageValue)) {
                if (unit.sensors[s].sensorType !== "wheelpurple" && unit.sensors[s].sensorType !== "wheelred" && unit.sensors[s].sensorType !== "wheelorange") {
                    unit.sensors[s].sensorType = "wheelyellow";
                    unit.sensors[s].sensorSnipType = "wheelsnipyellow";
                }
                unit.sensors[s].voltageLabelColour = "yellow";
            }
        }
    }

    return unit;
}

export const getTyreArrayIndex = (tyre, axleSensors) => {

    let tyreArrayIndex = 0;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].sensorName.slice(1, 2)) === tyre) {
            tyreArrayIndex = s;
            break;
        }
    }
    return tyreArrayIndex;
}