// Load Styles
import './scss/main.scss';

// Load Bootstrap init
import { initBootstrap } from './js/bootstrap.js';

import globals from './js/utils/globals.js';

import { onlineConnection } from './js/utils/connectionhandler.js';
import { stringify } from './js/utils/general.js';

import VanillaRouter from './js/router/vanillarouter.js';

import { getUnitData } from './js/data/getunitdata.js';

import { handleError } from './js/utils/errorhandler.js';


// window.onload = () => {  
//   'use strict';   
//   console.log(`register`);
//   if ("serviceWorker" in navigator) {
//     console.log(`register1`);
//     navigator.serviceWorker.register("../sw.js");
//   }
// }
//global.selectedUnit = {};

var gIntervalId = 0;

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Your app code
//(() => { getToken(); })(); 

getOnlineStatus();

function isOnline(online) {

  const onlineStatus = document.getElementById('appstatus');
  // Update the DOM to reflect the current status
  if (online) {
    while (onlineStatus.hasChildNodes()) {
      onlineStatus.removeChild(onlineStatus.lastChild);
    }
  } else {
    const spanOnlineStatus = document.createElement('span');
    spanOnlineStatus.className = 'appstatus offline';
    spanOnlineStatus.innerText = "OFFLINE - page content might be out of date.";
    onlineStatus.appendChild(spanOnlineStatus);
  }  
}

export function logStatus(logText) {

  const onlineStatus = document.getElementById('appstatus');
  const onlineStatusDiv = document.createElement('div');
  onlineStatusDiv.className = 'appstatus offline';

  const spanTime = document.createElement('span');
  spanTime.innerText = `[${new Date().toLocaleTimeString()} - ${globals.currentPage}] `;

  const spanOnlineStatus = document.createElement('span');  
  spanOnlineStatus.innerText = logText;
  onlineStatusDiv.appendChild(spanTime);
  onlineStatusDiv.appendChild(spanOnlineStatus);

  onlineStatus.appendChild(onlineStatusDiv);

  return;
}

function getOnlineStatus() {
  try {  
    window.addEventListener("load", (ev) => {
      ev.preventDefault();
      isOnline(onlineConnection());

      window.addEventListener("online", (e) => {
        e.preventDefault();
        //isOnline(true);              
        isOnline(onlineConnection());  
      });
    
      window.addEventListener("offline", (e) => {
        e.preventDefault();
        isOnline(false);
      });    
      getToken();
    });
  } catch (error) {
      handleError(error, "", "mainJS -> getOnlineStatus");
  }
}

export function getToken() {

  //<iframe name="simple_form" src="//hosting.wialon.com/login_simple.html?cms_url=http://cms.wialon.com&cms_title=CMS&lite_url=http://lite.wialon.com&mobile_url=http://m.wialon.com&demo_title=Demo login&demo_url=http://hosting.wialon.com/?token=5dce19710a5e26ab8b7b8986cb3c49e54E9D73F9D2D2C1A4808D6F032006CE3267346C30&title=Monitoring&lang=en" scrolling="no" style="width: 230px; height: 290px; border: 0; margin: 10px;"></iframe>
  try {  

    let version = "1.0.39";
    let token = getTokenString();     
    // console.log(`TOKEN ${token}`)   
    // token = token +'123';
    
    if (token) {  
      showSplash(version, "");
      globals.token = token;
      tokenReceived(token);   
    } else {  
      showLoginPage(version);
    }
  } catch (e) {
    if (globals.token == null)    
      handleError(e, "", "getToken");
    else 
      handleError(e, globals.token, "getToken");
  }
}

export function tokenReceived(token) {
    
  try {
    let session = wialon.core.Session.getInstance();
    session.initSession("https://hst-api.wialon.com");

    session.loginToken(token, "", async function (error) {
        if (error) {            
            console.log(`mainJS: tokenReceived - TOKEN: ${token}; ERROR: ${error} ->(${wialon.core.Errors.getErrorText(error)})`);
            //alert(`ERROR: ${error} ->(${wialon.core.Errors.getErrorText(error)})`); 
            if (error === 4 || error === 8 ) signOut();                      
            
            // logStatus(`tokenReceived -> session.loginToken TOKEN: yes; ERROR: ${error} ->(${wialon.core.Errors.getErrorText(error)})`);
            // RATHER TRY AGAIN
            // localStorage.removeItem("tePressureTrack");
            // handleError(error, "", "tokenReceived -> session.loginToken");
        } else {

          // if ("serviceWorker" in navigator && "SyncManager" in window) {
          //   navigator.serviceWorker.ready.then(function(registration) {
          //     registration.sync.register("sync-reservations");
          //   });
          // }
          // if ("serviceWorker" in navigator) {
          //   navigator.serviceWorker.ready.then(function(registration) {
          //     console.log(`service worker in navigator`);
          //     registration.sync.register("sync-user"); //Could store token in cache?
          //   });
          // } else {
          //   console.log(`service worker NOT IN navigator`);
          // }
         

        window.onblur = (e) => {  
          console.log("tokenReceived(): window.onblur -> onSleepSignOut()");
          console.log(e);
          e.preventDefault();
          onSleepSignOut();
        }
    
        window.addEventListener('pagehide', (e) => { 
          console.log("event => PAGEHIDE, addEventListener event");
          console.log(e);
          e.preventDefault();
          // logStatus(` tokenReceived(): window.addEventListener PAGEHIDE -> onSleepSignOut()`);
          onSleepSignOut();
        });

        const userName = session.getCurrUser().getName(); 
        document.getElementById('username').innerText = userName; 

        const signoutModalLabel = document.getElementById('signoutmodallabel');  
        signoutModalLabel.innerText = userName;      
        const signOutButton = document.getElementById('signoutbutton');
        signOutButton.onclick = () => {
            signOut();
        }

        getUnitData().catch((error) => {
            // logStatus(` tokenReceived(): getUnitData().catch -> handleError()`);
            console.log("mainJS: tokenReceived(getUnitData) - ERROR " + error + ")");
            handleError(error, token, 'tokenReceived => getUnitData');            
        });

        if (gIntervalId) {
            clearInterval(gIntervalId);
            console.log(`mainJS -> tokenReceived - CLEAR Interval Id: ${gIntervalId}`);
        }

        gIntervalId = setInterval(() => getUnitData().catch((error) => {
          if (error.type === 'API_ERROR') {
            const reason = error.fullError && error.fullError.reason;
            console.log(
                wialon.core.Errors.getErrorText(error.code) +
                (reason ? ' ' + reason : '')
            );
            console.log("mainJS: tokenReceived(getUnitData) - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
            handleError(error, token, 'tokenReceived => getUnitData');
          } else {
              console.log("mainJS: tokenReceived(getUnitData) - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
              handleError(error, token, 'tokenReceived => getUnitData');
          }
        }), 120000);     
      }
    });
  } catch (e) {    
    console.error('mainJS: tokenReceived - ERROR: ', e);
    handleError(e, token, 'tokenReceived');
  }
}

const showLoginPage = (version) => {

  const headerNavMain = document.getElementById('headernavmain');
  headerNavMain.className ='headernavmain-login d-flex justify-content-center align-items-center';     
  while (headerNavMain.hasChildNodes()) {
    headerNavMain.removeChild(headerNavMain.lastChild);
  }

  const headerLogo = document.createElement('div');
  headerLogo.id = 'headerlogo';
  headerLogo.className = 'headerlogo-login';
  headerNavMain.appendChild(headerLogo);

  const wialonLogin = document.getElementById('wialon-login');
  wialonLogin.className ='wialon-login';     
  // iFrame 
  const dns = "https://hosting.wialon.com";
  const wialonUrl = `${dns}/login.html?client_id=PressureTrack&access_type=-1&activation_time=0&duration=2592000&flags=0x1&redirect_uri=${dns}/post_token.html`;
  // const wialonUrl = `https://hosting.wialon.com/login.html?client_id=PressureTrack&access_type=-1&activation_time=0&duration=2592000&flags=0x1&redirect_uri=https://hosting.wialon.com/post_token.html`;
                                                                                 
  wialonLogin.src = wialonUrl;
  // wialonLogin.scrolling="no";
  // wialonLogin.style="width: 230px; height: 290px; border: 0; margin: 10px;"
  // // logStatus(` NO event: getToken() => token NOT found TOKEN : **${token}***`); 
  window.onmessage = function (e) {
    const msg = e.data;
    if (typeof msg == "string" && msg.indexOf("access_token=") >= 0) {
      let token = msg.replace("access_token=", "");
      if(token){
        showSplash(version, "");
        storeToken(token);
        globals.token = token;
        tokenReceived(token);
      } else {            
        console.log(`Sign In failed! ${msg}`)
        handleError(msg, "", 'getToken => window.onmessage');            
      }
    }
  };
  
  const footerLogin = document.getElementById('diagramtoggle');    
  while (footerLogin.hasChildNodes()) {
    footerLogin.removeChild(footerLogin.lastChild);
  }
  footerLogin.className = 'footermain-login'; // align-items-end d-flex 

  const loginVersion = document.createElement('div');
  loginVersion.className = 'version';
  loginVersion.innerText = `version ${version}`;   

  footerLogin.appendChild(loginVersion);
}

const showSplash = (version, userName) => {

  document.getElementById('header').style.display = 'none';
  document.getElementById('footer').style.display = 'none';

  const splashContainer = document.getElementById('splashcontainer');
  splashContainer.className = 'container-splash';
  while (splashContainer.hasChildNodes()) {
      splashContainer.removeChild(splashContainer.lastChild);
  }
  const splashFragment = document.createDocumentFragment();

  const splash = document.createElement('div');
  splash.id = 'splash';

  const splashIcon = document.createElement('div');
  splashIcon.id = 'splashicon';
  splashIcon.className = 'splashicon';
  splash.appendChild(splashIcon);

  const splashLogo = document.createElement('div');
  splashLogo.id = 'splashlogo';
  splashLogo.className = 'splashlogo';
  splash.appendChild(splashLogo);

  const splashLabel = document.createElement('div');
  splashLabel.id = 'splashlabel';
  splashLabel.className = 'splashlabel';
  splashLabel.innerText = `If you can't measure it, you can't manage it.`;
  splash.appendChild(splashLabel);

  const userNameSplash = document.createElement('div');
  userNameSplash.id = 'username';
  userNameSplash.className = 'username';
  userNameSplash.innerText = userName;
  splash.appendChild(userNameSplash);

  const versionSplash = document.createElement('div');
  versionSplash.id = 'splashversion';
  versionSplash.className = 'version';
  versionSplash.innerText = `version ${version}`;
  splash.appendChild(versionSplash);

  splashFragment.appendChild(splash);
  splashContainer.appendChild(splashFragment);

  // const headerLogo = document.getElementById('headerlogo'); 
  // headerLogo.className = '';

  // const userNameSplash = document.getElementById('username');
  // userNameSplash.innerText = userName;

  // const splashMain = document.getElementById('maincontainer');
  // splashMain.className = 'container-splash';
  // while (splashMain.hasChildNodes()) {
  //   splashMain.removeChild(splashMain.lastChild);
  // }
  // const splashFragment = document.createDocumentFragment();

  // let splash = document.createElement('div');
  // splash.id = 'splash';

  // const splashIcon = document.createElement('div');
  // splashIcon.id = 'splashicon';
  // splashIcon.className = 'splashicon';
  // splash.appendChild(splashIcon);
  // const splashLogo = document.createElement('div');
  // splashLogo.id = 'splashlogo';
  // splashLogo.className = 'splashlogo';
  // splash.appendChild(splashLogo);
  // const splashLabel = document.createElement('div');
  // splashLabel.id = 'splashlabel';
  // splashLabel.className = 'splashlabel'
  // splashLabel.innerText = `If you can't measure it, you can't manage it.`;
  // splash.appendChild(splashLabel);   
  
  // splashFragment.appendChild(splash);   
  // splashMain.appendChild(splashFragment);
}

const clearStorage = () => {
  localStorage.removeItem("tePressureTrack");
  localStorage.removeItem("pPressureTrack");
  localStorage.removeItem("uPressureTrack");    
}

export const onSleepSignOut = () => {
  
  window.onfocus = (e) => { 
  //window.addEventListener('focus', (e) => {
    e.preventDefault();
    // logStatus(` event: window.onfocus -> getToken()`);
    getToken();
  }
  //);

  window.onresize = () => {
    getToken();
  }

  window.addEventListener("touchend", () => {
    location.reload(true); 
    getToken();
  });

  // addEventListener('pageshow', () => {
  //   history.replaceState({}, document.title, window.location.pathname);
  //   // called on initial load and first back
  // });

  // function isSafariMobile() {
  //   return navigator && /Safari/.test(navigator.userAgent) && /iPhone|iPad/.test(navigator.userAgent)
  // }

  // let myCustomEvent =
  // navigator.userAgent.match('iPhone') != null ? 'popstate' : 'pageshow';
  // window.on(myCustomEvent, function (e) {
  //   alert(`add this!`); // 'popstate' called on all back events
  // });

  // window.onpageshow = function(event) { //Persisted is false on initial page load, so you can check against it, and if it false, it is your first page load.
  //   if (!event.persisted) {
  //       alert("hello");
  //   }
  // };

  window.addEventListener('pageshow', (e) => { 
    e.preventDefault();
    // logStatus(` event: window.addEventListener PAGESHOW`);
    location.reload(true); 
    getToken();
  });

  if (gIntervalId) {
    clearInterval(gIntervalId);
    gIntervalId = 0;  
    // logStatus(` onSleepSignOut(): gIntervalId = 0`);        
  }
  const session = wialon.core.Session.getInstance();
  console.log(` onSleepSignOut() => session ${session}`)
  console.log(session);
  // logStatus(` onSleepSignOut() => sessionId ${session.getId()}`)
  if (session && session.getId()) {
      session.logout( 
          (error) => { 
              if (error) {
                  console.log(`mainJS: onSleepSignOut(): wialon.core.Session.getInstance().logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`);
                  // logStatus(` onSleepSignOut() => session.logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`);
                  // handleError(error, "", "onSleepSignOut => session.logout");
                  // clearStorage();
              }
                // logStatus(` onSleepSignOut() => session.logout: Logged OUT success!`);
                location.reload(true);           
          }          
      );
  } else {
      console.log(`mainJS: onSleepSignOut => No Session`);
      // logStatus(` onSleepSignOut => No Session`);
      location.reload(true);
  } 
}

export const signOut = () => {
  try{
 
  if (gIntervalId) {
    clearInterval(gIntervalId);
    gIntervalId = 0;          
  }
	const session = wialon.core.Session.getInstance();
  
	if (session && session.getId()) {
    session.logout( 
		  (error) => { 
        if (error) {
          console.log(`mainJS: signOut => wialon.core.Session.getInstance().logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`); // logout failed, print error
        }
        clearStorage();
        location.reload(true);
      }
    );
  } else {
    clearStorage();
    location.reload(true);
  }
} catch (e){
  console.log(`e: signOut => wialon.core.Session.getInstance().logout ERROR: ${e} `); 
  clearStorage();
  location.reload(true);
}
}

const storeToken = (token) => {  
  if(token === ""){
    localStorage.removeItem("tePressureTrack");
  }
  else {
    localStorage.setItem("tePressureTrack", eString(token));
  }  
  return;
}

function getTokenString() {

  let tString = "";
  const eString = localStorage.getItem("tePressureTrack"); 

  if (eString) {
     tString = deString(eString); 
     globals.currentPage = localStorage.getItem("pPressureTrack");  
     globals.selectedUnit = JSON.parse(localStorage.getItem("uPressureTrack"));  
  } 

  return tString;
}

function eString(str) {

  let result = "";
  for (let i = 0; i < str.length; i++) {
    let char = (str.charCodeAt(i) + 32) % 256;
    result += String.fromCharCode(char);
  }  
  return result;

}

function deString(str) {

  let result = "";
  for (let i = 0; i < str.length; i++) {
    let char = (str.charCodeAt(i) - 32 + 256) % 256;
    result += String.fromCharCode(char);
  }
  return result;

}

const router = new VanillaRouter({
  type: 'history',
  routes: {
    '/': 'unitslist',
    '/sensorvaluesdiagram': 'sensorvaluesdiagram',
    '/wheelschematic': 'wheelschematic',
    '/unitmap': 'unitmap'
  }
})
.listen()
.on('route', async e => {e.detail.route, e.detail.url});

function show(html) {
  document.body.innerHTML = html;
}

function unitsListRouteHandler() {
  const content = '<h1>Units List view</h1><p>This is the units list. Wanna see the <a href="/#sensorvaluesdiagram">Sensorvalues Diagram</a> section?';
  show(content);
}

function sensorValuesdiagramRouteHandler() {
  const content = '<h1>This is sensor values diagram</h1><p>diagram... Go <a href="/">Units List</a>?';
  show(content);
}
