import '../../scss/main.scss';

import globals from '../utils/globals.js';

import {showUnitsListScreen} from '../screens/unitslistscreen.js';
import {showDiagramScreen}  from './diagramscreen.js';
import {showUnitMapScreen} from '../screens/unitmapscreen.js';

import { createHeaderExitNav, createHeaderMain } from './components/headers.js';

export const createDetailPage = (units, unit) => {

    //window.scrollTo(0, 0);
    setTimeout(() => {
        window.scroll({ top: -1, left: 0, behavior: "smooth" });    
    }, 10); 
    
    createPageHeader(units, unit);
    createPageFooter(units, unit);

    if(globals.wheelReport) { 
        globals.wheelReport = false;
    }

    switch (globals.currentPage) {
        case 'UNITMAP':
            globals.currentPage = 'UNITMAP';
            localStorage.setItem("pPressureTrack", 'UNITMAP');            
            showUnitMapScreen(unit); 
            break;
        case 'WHEELSDIAGRAM':
            globals.currentPage = 'WHEELSDIAGRAM';
            localStorage.setItem("pPressureTrack", 'WHEELSDIAGRAM');
            showDiagramScreen(unit); 
            break;
        case 'SENSORVALUESDIAGRAM':
            globals.currentPage ='SENSORVALUESDIAGRAM';
            localStorage.setItem("pPressureTrack", 'SENSORVALUESDIAGRAM');
            showDiagramScreen(unit); 
            break;
        case 'UNITSLIST':
            // globals.currentPage='SENSORVALUESDIAGRAM';
            // showSensorValuesDiagramScreen(unit); 
            globals.currentPage = 'UNITSLIST';
            localStorage.setItem("pPressureTrack", 'UNITSLIST');
            showUnitsListScreen(units);
            break;
        default:
            showUnitsListScreen(units);
            break;
    }
}

const createPageHeader = (units, unit) => {

    const headerNavMain = document.getElementById('headernavmain');
    headerNavMain.className ='navbar navbar-light bg-light pe-1 flex-nowrap'; // container-fluid p-2
    while (headerNavMain.hasChildNodes()) {
        headerNavMain.removeChild(headerNavMain.lastChild);
    }   

    const backNav = createHeaderBackNav(units, unit);
    const headerMain = createHeaderMain(unit);
    const exitNav = createHeaderExitNav(unit);

    headerNavMain.appendChild(backNav);
    headerNavMain.appendChild(headerMain);
    headerNavMain.appendChild(exitNav);

}

const createHeaderBackNav = (units, unit) => {

    const backNav = document.createElement('div');
    backNav.id = 'headerbacknav';

    const backSVG = document.createElement('svg');   
    backSVG.className = 'imgbutton buttonback'; 

    backNav.appendChild(backSVG);

    backNav.onclick = () => {
        globals.currentPage = 'UNITSLIST';
        showUnitsListScreen(units);
        localStorage.setItem("pPressureTrack", 'UNITSLIST');
        // if(globals.currentPage === 'SENSORVALUESDIAGRAM' || globals.currentPage === 'WHEELSDIAGRAM') { 
        //     globals.currentPage = 'UNITSLIST';
        //     showUnitsListScreen(units);
        //     localStorage.setItem("pPressureTrack", 'UNITSLIST');
        // } else {
        //     const toggle = document.getElementById('toggle');   
        //     if (toggle.checked === true){
        //         showDiagramScreen(unit); 
        //         globals.currentPage = 'SENSORVALUESDIAGRAM';
                
        //         localStorage.setItem("pPressureTrack", 'SENSORVALUESDIAGRAM');
        //     } else {
        //         showDiagramScreen(unit); 
        //         globals.currentPage = 'WHEELSDIAGRAM';
                
        //         localStorage.setItem("pPressureTrack", 'WHEELSDIAGRAM');
        //     }
        // }
    }

    return backNav;
};

const createPageFooter = (units, unit) => {

    createDiagramToggle(units, unit);
    createFooterbuttons(units, unit);
    
}

const createDiagramToggle = (units, unit) => {

    const diagramToggle = document.getElementById('diagramtoggle');   
    diagramToggle.className = 'footernav row p-2 border-bottom border-pt-border'; 
    while (diagramToggle.hasChildNodes()) {
        diagramToggle.removeChild(diagramToggle.lastChild);
    }   
    const showSensorValuesLabel = document.createElement('div');   
    showSensorValuesLabel.className = 'col-8 my-auto showsensorvalueslabel';
    showSensorValuesLabel.innerText ='Show Sensor Values';
    diagramToggle.appendChild(showSensorValuesLabel);

    const toggleDiv = document.createElement('div');  
    toggleDiv.className = 'col form-check form-switch form-switch-md p-2 me-2';
    const toggleInput = document.createElement('input');   
    toggleInput.className = 'form-check-input float-end';  
    toggleInput.id='toggle'; 
    toggleInput.type='checkbox';
    toggleInput.role ='switch';
    //toggleInput.checked=true;

    const toggleLabel = document.createElement('label');  
    toggleLabel.className = 'form-check-label';  
    toggleLabel.for='toggle'

    // showSensorValuesText.id = 'showsensorvaluestext';
    // showSensorValuesText.innerText = 'Show Sensor Values';

    toggleDiv.appendChild(toggleInput);
    toggleDiv.appendChild(toggleLabel);

    diagramToggle.appendChild(toggleDiv);

    diagramToggle.onclick = () => {
        //if(!toggleInput.disabled){
            if (toggleInput.checked === true){
                if(globals.currentPage != 'SENSORVALUESDIAGRAM' ) { 
                    globals.currentPage = 'SENSORVALUESDIAGRAM';
                }   
                localStorage.setItem("pPressureTrack", 'SENSORVALUESDIAGRAM');
                // showSensorValuesDiagramScreen(units, unit);
                showDiagramScreen(unit); 
            } else {
                if(globals.currentPage != 'WHEELSDIAGRAM' ) { 
                    globals.currentPage = 'WHEELSDIAGRAM';
                }   
                localStorage.setItem("pPressureTrack", 'WHEELSDIAGRAM');
                showDiagramScreen(unit); 
            }
        //}
    };
}

const createFooterbuttons = (units, unit) => {

    const footerButtons = document.getElementById('footerbuttons');    
    while (footerButtons.hasChildNodes()) {
        footerButtons.removeChild(footerButtons.lastChild);
    }
    footerButtons.className = 'footernav row p-2 text-center';

    const unitButton = document.createElement('div');   
    unitButton.className = 'col border-end border-pt-border';

    const tyreSVG = document.createElement('svg');   
    tyreSVG.className = 'imgbutton buttontyre-enabled'; //class="button imgbutton buttontyre-disabled"

    const unitButtonText = document.createElement('span');   
    unitButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    unitButtonText.innerText='Unit' //'\xa0Unit'

    unitButton.appendChild(tyreSVG);
    unitButton.appendChild(unitButtonText);

    const mapButton = document.createElement('div');   
    mapButton.className = 'col border-end border-pt-border';

    const pinSVG = document.createElement('svg');   
    pinSVG.className = 'imgbutton buttonpin-disabled'; //class="button imgbutton buttontyre-disabled"

    const mapButtonText = document.createElement('span');   
    mapButtonText.className = 'buttontext buttontext-disabled'; //class="buttontext buttontext-disabled"
    mapButtonText.innerText='Map' //'\xa0Unit'

    mapButton.appendChild(pinSVG);
    mapButton.appendChild(mapButtonText);

    footerButtons.appendChild(unitButton);
    footerButtons.appendChild(mapButton);

    unitButton.onclick = (e) => {
        if(globals.wheelReport) { 
            //updateFooter(unit); 
            globals.wheelReport = false;
        }    
         
        tyreSVG.className = 'imgbutton buttontyre-enabled'; 
        pinSVG.className = 'imgbutton buttonpin-disabled'; 
        // globals.currentPage='WHEELSDIAGRAM';
        // localStorage.setItem("pPressureTrack", 'WHEELSDIAGRAM');
        // showDiagramScreen(unit);
        const toggleInput = document.getElementById('toggle'); 
        toggleInput.disabled = false;
        if (toggleInput.checked===true){
            if(globals.currentPage != 'SENSORVALUESDIAGRAM' ) { 
                globals.currentPage = 'SENSORVALUESDIAGRAM';
            }   
            localStorage.setItem("pPressureTrack", 'SENSORVALUESDIAGRAM');
            // showSensorValuesDiagramScreen(units, unit);
            showDiagramScreen(unit); 
        } else {
            if(globals.currentPage != 'WHEELSDIAGRAM' ) { 
                globals.currentPage = 'WHEELSDIAGRAM';
            }   
            localStorage.setItem("pPressureTrack", 'WHEELSDIAGRAM');
            showDiagramScreen(unit); 
        }
    };

    mapButton.onclick = (e) => {
        if(globals.wheelReport) { 
            //updateFooter(unit); 
            globals.wheelReport = false;
        } 
        const toggleInput = document.getElementById('toggle'); 
        // toggleInput.disabled = true;
        pinSVG.className = 'imgbutton buttonpin-enabled';  
        tyreSVG.className = 'imgbutton buttontyre-disabled'; 
        globals.currentPage='UNITMAP';
        localStorage.setItem("pPressureTrack", 'UNITMAP');
        showUnitMapScreen(unit);
    };
}

function createHeaderElementOld(units, unit) {

    const detailPageHeader = document.createElement('div');
    // const detailPageHeaderClasses = ['framebackground'];   
    // detailPageHeader.classList.add(...detailPageHeaderClasses);
    
    //const navBarClasses = ['navbar', 'navbar-expand-lg', 'bg-light', 'aligns-items-center', 'justify-content-center', 'my-1'];
    const navBarClasses = ['navbar', 'navbar-expand-xxl', 'bg-light', 'aligns-items-center', 'justify-content-center'];
    const navBar = document.createElement('nav');
    navBar.classList.add(...navBarClasses);

    const navDivClasses = ['d-flex', 'gap-2']; //d-grid gap-2 d-md-flex 'text-center'
    const navDiv = document.createElement('div');
    navDiv.classList.add(...navDivClasses);

    const navButtonClasses = ['btn', 'btn-primary'];

    const navUnitsListButton = document.createElement('button');
    navUnitsListButton.classList.add(...navButtonClasses);
    navUnitsListButton.type="button";

    const buttonTextClasses = ['d-none', 'd-sm-block'];
    const unitsListButtonIconClasses = ['fa', 'fa-bars'];
    const unitsListButtonIcon = document.createElement('i');
    unitsListButtonIcon.classList.add(...unitsListButtonIconClasses);
    
    const unitsListButtonText = document.createElement('span');    
    unitsListButtonText.textContent = "Units List";
    unitsListButtonText.classList.add(...buttonTextClasses);
    navUnitsListButton.appendChild(unitsListButtonIcon);
    navUnitsListButton.appendChild(unitsListButtonText);

    navUnitsListButton.onclick = (e) => {
        if(globals.wheelReport) { 
            globals.wheelReport = false;
        }  
        globals.currentPage='UNITSLIST';   
        localStorage.setItem("pPressureTrack", 'UNITSLIST');
        showUnitsListScreen(units)
    };
    navDiv.appendChild(navUnitsListButton);

    const navSensorValuesDiagramButton = document.createElement('button');
    navSensorValuesDiagramButton.classList.add(...navButtonClasses);
    navSensorValuesDiagramButton.type="button";
    const sensorValuesDiagramButtonIconClasses = ['fa', 'fa-credit-card'];
    const sensorValuesDiagramButtonIcon = document.createElement('i');
    sensorValuesDiagramButtonIcon.classList.add(...sensorValuesDiagramButtonIconClasses);
    const sensorValuesDiagramButtonText = document.createElement('span');    
    sensorValuesDiagramButtonText.textContent = "Sensor Values";
    sensorValuesDiagramButtonText.classList.add(...buttonTextClasses);
    navSensorValuesDiagramButton.appendChild(sensorValuesDiagramButtonIcon);
    navSensorValuesDiagramButton.appendChild(sensorValuesDiagramButtonText);
    navSensorValuesDiagramButton.onclick = (e) => {
        if(globals.wheelReport) { 
            updateFooter(unit); 
            globals.wheelReport = false;
        }  
        globals.currentPage='SENSORVALUESDIAGRAM';
        localStorage.setItem("pPressureTrack", 'SENSORVALUESDIAGRAM');
        // showSensorValuesDiagramScreen(units, unit)};
        showDiagramScreen(unit); 
    }
    navDiv.appendChild(navSensorValuesDiagramButton);

    const navWheelsDiagramButton = document.createElement('button');
    navWheelsDiagramButton.classList.add(...navButtonClasses);
    navWheelsDiagramButton.type="button";
    const wheelsDiagramButtonIconClasses = ['fa', 'fa-bus'];
    const wheelsDiagramButtonIcon = document.createElement('i');
    wheelsDiagramButtonIcon.classList.add(...wheelsDiagramButtonIconClasses);
    const wheelsDiagramButtonText = document.createElement('span');    
    wheelsDiagramButtonText.textContent = "Wheels Diagram";
    wheelsDiagramButtonText.classList.add(...buttonTextClasses);
    navWheelsDiagramButton.appendChild(wheelsDiagramButtonIcon);
    navWheelsDiagramButton.appendChild(wheelsDiagramButtonText);
    navWheelsDiagramButton.onclick = (e) => {
        if(globals.wheelReport) { 
            updateFooter(unit); 
            globals.wheelReport = false;
        }    
        globals.currentPage='WHEELSDIAGRAM';
        localStorage.setItem("pPressureTrack", 'WHEELSDIAGRAM');
        showDiagramScreen(unit)
    };
    navDiv.appendChild(navWheelsDiagramButton);

    const navViewMapButton = document.createElement('button');
    navViewMapButton.classList.add(...navButtonClasses);
    navViewMapButton.type="button";
    const viewMapButtonIconClasses = ['fa', 'fa-map-marker'];
    const viewMapButtonIcon = document.createElement('i');
    viewMapButtonIcon.classList.add(...viewMapButtonIconClasses);
    const viewMapButtonText = document.createElement('span');    
    viewMapButtonText.textContent = "View Map";
    viewMapButtonText.classList.add(...buttonTextClasses);
    navViewMapButton.appendChild(viewMapButtonIcon);
    navViewMapButton.appendChild(viewMapButtonText);
    navViewMapButton.onclick = (e) => {
        if(globals.wheelReport) { 
            updateFooter(unit); 
            globals.wheelReport = false;
        }   
        globals.currentPage='UNITMAP';
        localStorage.setItem("pPressureTrack", 'UNITMAP');
        showUnitMapScreen(unit)};
    navDiv.appendChild(navViewMapButton);
    
    navBar.appendChild(navDiv);
    detailPageHeader.appendChild(navBar);

    return detailPageHeader;
}

const updateFooter = (unit) => {
    const detailPageFooter = document.getElementById('detailpagefooter');
    while (detailPageFooter.hasChildNodes()) {
        detailPageFooter.removeChild(detailPageFooter.lastChild);
    }
    let updatedFooter = createFooterElement(unit);
    detailPageFooter.appendChild(updatedFooter);
    return detailPageFooter;
}







