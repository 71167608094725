import '../../scss/main.scss';

import globals from '../utils/globals.js';

import { handleError } from '../utils/errorhandler.js';

import {getResource, getResourceNotifications} from '../data/getresourcedata.js'
  
var map, marker; // global variables for maps (leaflet)

export const showUnitMapScreen = async (unitData) => {

    const selectedUnit = {};
    selectedUnit.unitId = unitData.unitId;
    const unit = wialon.core.Session.getInstance().getItem(unitData.unitId);
    if (!unit) { 
        console.log("unitinfoJS: showUnitMapScreen - unit not found"); 
        return; 
    }

    selectedUnit.unitPosition = unit.getPosition();
    selectedUnit.unitLocation = (await getUnitLocation(selectedUnit.unitPosition))[0];
    
    const unitMapScreen = document.getElementById('maincontainer');
    while (unitMapScreen.hasChildNodes()) {
        unitMapScreen.removeChild(unitMapScreen.lastChild);
    }

    const unitMapFragment = document.createDocumentFragment();

    const unitMapMainDiv = document.createElement('div');
    unitMapMainDiv.id = 'unitmap';
    unitMapMainDiv.className = 'unitmap';

    unitMapFragment.appendChild(unitMapMainDiv);
    unitMapScreen.appendChild(unitMapFragment);

    // const headerDiv = createHeaderElement(unitData);
    // unitMapMainDiv.appendChild(headerDiv);

    const mapContainerDiv = createMapContainerElement(unitData);
    unitMapMainDiv.appendChild(mapContainerDiv);

    initialiseMap('mapcontainer');
    showUnitOnMap(unitData.unitIcon, selectedUnit.unitPosition);


}

async function getUnitLocation(unitPosition) {

    try {
        return await new Promise((resolve, reject) => {
            wialon.util.Gis.getLocations([{ lon: unitPosition.x, lat: unitPosition.y }],
                (error, address) => {
                    if (error) {
                        console.log("unitInfo: getUnitLocation - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        // reject({ type: 'API_ERROR', error: error });
                        // return;
                        handleError(error, globals.token, "getUnitLocation");
                    }
                    resolve(address);
                });
        });
    } catch (e) {
        console.error("unitInfo: getUnitLocation - ERROR ", e);
        handleError(e, globals.token, "getUnitLocation");
    }
}

function createMapContainerElement(unit) {

    let mapContainerDiv = document.createElement('div');
    mapContainerDiv.id = 'mapcontainer';
    mapContainerDiv.className = 'mapcontainer';
    mapContainerDiv.onclick = function (e) {
        e.stopImmediatePropagation();
    };

    return mapContainerDiv;
}

function initialiseMap(mapContainer) {

    // create a map in the "map" div, set the view to a given place and zoom
    map = L.map(mapContainer).setView([-32.57, 22.9375], 10);

    // add an OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://gurtam.com">Gurtam</a>'
    }).addTo(map);
}

function showUnitOnMap(unitIcon, pos) { // show selected unit on map
    //map = document.getElementById('map');
    if (map) { // check if map created
        let icon = L.icon({
            iconUrl: unitIcon
        });
        //if (!marker) {
        //    marker = L.marker({ lat: pos.y, lng: pos.x }, { icon: icon }).addTo(map);
        //} else {
        marker = L.marker({ lat: pos.y, lng: pos.x }, { icon: icon }).addTo(map);
        marker.setLatLng({ lat: pos.y, lng: pos.x });
        marker.setIcon(icon);
        //}
        map.setView({ lat: pos.y, lng: pos.x });
  
    }
}