import '../../scss/main.scss';

import globals from '../utils/globals.js';

import {formatDateTime, convertUnixTime} from '../utils/timeformats.js';

var gPressureChart, gTemperatureChart, gVoltageChart;

export const showWheelReport = (unit, axle, tyre, sensorValues) => {

    //window.scrollTo(0, 0);
    setTimeout(() => {
        window.scroll({ top: -1, left: 0, behavior: "smooth" });    
    }, 10); 

    globals.wheelReport = true;
    const selectedUnit = {};
    selectedUnit.unitId = unit.unitId;

    const wheelReportsContainer = document.getElementById('maincontainer');
    //wheelReportsContainer.scrollIntoView(true);

    wheelReportsContainer.className='maincontainer';
    while (wheelReportsContainer.hasChildNodes()) {
        wheelReportsContainer.removeChild(wheelReportsContainer.lastChild);
    }

    const wheelReportsFragment = document.createDocumentFragment();

    const chartReport = createChartReport(unit, axle, tyre, sensorValues);
    wheelReportsFragment.appendChild(chartReport);

    wheelReportsContainer.appendChild(wheelReportsFragment); 

    const waitCursor = document.getElementById('waitcursor');              
    while (waitCursor.hasChildNodes()) {
        waitCursor.removeChild(waitCursor.lastChild);
    }

    const toggles = [
    'pressuretoggle',
    'temperaturetoggle',
    'voltagetoggle'
    ];
    for (let key = 0; key < toggles.length; key++) {
        const toggle = document.getElementById(toggles[key]);  
        handleToggle(toggle, toggles[key].replace("toggle", "").toUpperCase(), sensorValues);
    }  
}

function handleToggle (toggle, metric, sensorValues) {
    toggle.onclick = (e) =>{  
        if (toggle.classList.contains('toggleless')){
            if (metric === 'PRESSURE') { 
                const pressureChart = document.getElementById('pressurechart');  
                pressureChart.parentNode.removeChild(pressureChart);  
            }
            if (metric === 'TEMPERATURE') {
                const temperatureChart = document.getElementById('temperaturechart');
                temperatureChart.parentNode.removeChild(temperatureChart); 
            }
            if (metric === 'VOLTAGE') {
                const voltageChart = document.getElementById('voltagechart');
                voltageChart.parentNode.removeChild(voltageChart); 
            }                
            toggle.className = 'imgbutton togglemore';
        } else {
            if (metric === 'PRESSURE') { 
                const pressureChart = createPressureChart(sensorValues);
                const pressureMetric = document.getElementById('pressuremetric');
                pressureMetric.appendChild(pressureChart);  
            }
            if (metric === 'TEMPERATURE') {
                const temperatureChart = createTemperatureChart(sensorValues);
                const temperatureMetric = document.getElementById('temperaturemetric');
                temperatureMetric.appendChild(temperatureChart);         
            }
            if (metric === 'VOLTAGE') {
                const voltageChart = createVoltageChart(sensorValues);
                const voltageMetric = document.getElementById('voltagemetric');
                voltageMetric.appendChild(voltageChart); 
            }    
            toggle.className = 'imgbutton toggleless';
        } 
      }

}

function createChartReport(unit, axle, tyre, sensorValues) {

    const chartReport = document.createElement('div');
    
    const reportHeader = createReportHeaderElement(unit, axle, tyre);
    chartReport.appendChild(reportHeader);

    const sensor = unit.sensors.filter(s => s.sensorName.slice(0, 2) === `${axle}${tyre}`);
    
    const metricOptions = [
        'PRESSURE',
        'TEMPERATURE',
        'VOLTAGE'
      ];

    for (let key = 0; key < metricOptions.length; key++) {
        const reportMetricElement = createReportMetricElement(metricOptions[key] , sensor[0], sensorValues)
        chartReport.appendChild(reportMetricElement);
    }   

  return chartReport;
}

function createReportHeaderElement(unit, axle, tyre ) {

    const reportHeader = document.createElement('div');
    reportHeader.className = 'm-2';

    const wheelHeader = document.createElement('div');
    wheelHeader.className = 'h2';    
    wheelHeader.innerText = `A${axle}-T${tyre}`;

    const unitHeader = document.createElement('div');
    unitHeader.innerText = unit.unitName;

    reportHeader.appendChild(wheelHeader);
    reportHeader.appendChild(unitHeader);

    return reportHeader;
}

function createReportMetricElement(metric, sensor, sensorValues) {

    const metricElement = document.createElement('div');  

    metricElement.className = 'containersection reportsection';
    if (metric === 'PRESSURE') {
        metricElement.id = 'pressuremetric';
        const pressureMetric = createMetricElements(metric, sensor.pressureValue, sensor.pressureLabelColour); 
        const pressureChart = createPressureChart(sensorValues);
        metricElement.appendChild(pressureMetric);
        metricElement.appendChild(pressureChart);
    }
    if (metric === 'TEMPERATURE') {
        metricElement.id = 'temperaturemetric';
        const temperatureMetric = createMetricElements(metric, sensor.temperatureValue, sensor.temperatureLabelColour);
        const temperatureChartElement = createTemperatureChart(sensorValues);
        metricElement.appendChild(temperatureMetric);
        //metricElement.appendChild(temperatureChartElement);
    }
    if (metric === 'VOLTAGE') {
        metricElement.id = 'voltagemetric';
        const voltageMetric = createMetricElements(metric, sensor.voltageValue, sensor.voltageLabelColour);
        const voltageChartElement = createVoltageChart(sensorValues);
        metricElement.appendChild(voltageMetric);
        //metricElement.appendChild(voltageChartElement);
    }   
    return metricElement;
}

function createMetricElements(metric, metricValue, metricStatus) {

    const metricElements = document.createElement('div');   
    metricElements.className = 'row d-flex justify-content-center aligns-items-center p-2';

    const icon = document.createElement('div');  
    icon.className = 'col-2 my-auto';
    const image = document.createElement('div');   
    
    const label = document.createElement('div');   
    label.className = 'col';
    const labelText = document.createElement('div'); 
    labelText.className = 'labeltext';  
    const labelValue = document.createElement('div');    
    
    const status = document.createElement('div');   
    status.className = 'col-3 my-auto';
    const statusLabel = document.createElement('div');  

    const toggleIcon = document.createElement('div');   
    toggleIcon.className = 'col-2 mx-auto'; //ps-2 pe-2
    const toggle = document.createElement('div');  

    if (metric === "PRESSURE") {
        image.className = 'icon iconpressure';
        labelText.innerText = 'Pressure';
        labelValue.innerText = `${metricValue} bar`;  
        if (metricStatus === "red") {    
            statusLabel.className = 'statuslabel labelunderpressure'; 
        }
        if (metricStatus === "purple") {    
            statusLabel.className = 'statuslabel labeloverpressure'; 
        }
        toggle.id = 'pressuretoggle';
        toggle.className = 'imgbutton toggleless'; 
    }
    if (metric === "TEMPERATURE") {
        image.className = 'icon icontemperature'; 
        labelText.innerText = 'Temperature';
        labelValue.innerText = `${metricValue} \u2103`;  
        if (metricStatus === "orange") {    
            statusLabel.className = 'statuslabel labelhightemperature'; 
        }
        toggle.id = 'temperaturetoggle';
        toggle.className = 'imgbutton togglemore';
    }
    if (metric === "VOLTAGE") {
        image.className = 'icon iconvoltage';
        labelText.innerText = 'Battery Voltage';
        labelValue.innerText = `${metricValue} V`;  
        if (metricStatus === "yellow") {    
            statusLabel.className = 'statuslabel labellowvoltage'; 
        }
        toggle.id = 'voltagetoggle';
        toggle.className = 'imgbutton togglemore';
    } 

    icon.appendChild(image);
    label.appendChild(labelText);
    label.appendChild(labelValue);
    status.appendChild(statusLabel);
    toggleIcon.appendChild(toggle);

    metricElements.appendChild(icon);
    metricElements.appendChild(label);
    metricElements.appendChild(status);
    metricElements.appendChild(toggleIcon);
    
    return metricElements   
}

function createPressureChart(sensorValues) {

    const chartDiv = document.createElement('div');
    chartDiv.id = 'pressurechart';
    const chartCanvas = document.createElement('canvas');
    chartCanvas.id = 'pressurechartcanvas';
    chartDiv.appendChild(chartCanvas); 

    const chartContext = chartCanvas.getContext('2d');

    sensorValues = sensorValues.filter((value, index) =>
        index % 5 === 0
    );

    let chartAxesData = [];
    for (let i = 0; i < sensorValues.length; i++) {        
        let xyData = {};
        xyData.y = sensorValues[i].pressure;
        xyData.x = sensorValues[i].time;
        xyData.temperature = sensorValues[i].temperature;
        xyData.voltage = sensorValues[i].voltage;
        chartAxesData.push(xyData);
    }

    let pressureValueRange = [sensorValues[0].minPressureValue, sensorValues[0].maxPressureValue];
    let description = ["Min Press: ", "Max Press: "];
    let annotations = [];
    if (sensorValues[0].minPressureValue != null) {
        annotations = pressureValueRange.map(function (outOfRangeValue, index) {
            let colour = '#accf25';
            let position = 'center';
            let backgroundColour = 'white';
            if (index === 0) {
                colour = 'red'; //'#ad0505'
                position = 'start';
            }
            if (index === 1) {
                colour = '#320e75';
                position = 'start';
            }
            return {
                type: 'line',
                id: 'hline' + index,
                mode: 'horizontal',
                drawTime: 'beforeDatasetsDraw',
                yMin: outOfRangeValue,
                yMax: outOfRangeValue,
                borderColor: colour,
                borderWidth: 1,
                label: {
                    display: true,
                    padding: {
                        left: 2,
                        right: 2,
                    },
                    width: 50,
                    position: position,
                    color: colour,
                    backgroundColor: backgroundColour,
                    content: description[index] + outOfRangeValue + ' bar'
                }
            };
        });
    }

    let maxY = Math.max(...chartAxesData.map(c => c.y));
    maxY = Math.round(Math.max(maxY, sensorValues[0].maxPressureValue) + 1);

    const sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: 'A' + sensorValues[0].axleWheel.slice(0, 1) + '-T' + sensorValues[0].axleWheel.slice(1, 2),
                backgroundColor: '#075fa5',
                fill: false,
                borderColor: '#075fa5',
                data: chartAxesData,
                borderWidth: 0.5,
                radius: 0.25,
                hitRadius: 2.5,
                hoverRaius: 2.5,
                tension: 0.5,
                pointHoverRadius: 2.5
            }]
        },

        options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                y: {
                    beginAtZero: true,
                    max: maxY,
                    ticks: {
                        stepSize: 0.5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            hour: 'DD MMM',
                            day: 'DD MMM'
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                },
            },
            plugins: {
            //     tooltip: {                  
            //         callbacks: {
            //             label: (context) => {
            //               return context.dataset.label;
            //             },
            //             afterBody: (context) => {
            //                 const xtraData = [];
            //                 const temperature = context[0].raw.temperature.toString();
            //                 const voltage = context[0].raw.voltage.toString();
            //                 xtraData.push(context[0].label); 
            //                 xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar - ${temperature} \u2103 - ${voltage} V`);
            //                 return xtraData;
            //             }
            //     },
            // },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,                        
                    },
                    mode: "xy",
                }
            },
            annotation: {
                annotations: annotations
            },
        },
        layout: {
            padding: {
                bottom: 25,
            },
        },

        },
    }

    gPressureChart = new Chart(chartContext, sensorValuesChartData); 

    return chartDiv;

}

function createTemperatureChart(sensorValues) {

    const chartDiv = document.createElement('div');
    chartDiv.id = 'temperaturechart';
    const chartCanvas = document.createElement('canvas');
    chartCanvas.id = 'temperaturechartcanvas';
    chartDiv.appendChild(chartCanvas); 

    const chartContext = chartCanvas.getContext('2d');

    sensorValues = sensorValues.filter((value, index) =>
        index % 5 === 0
    );

    let chartAxesData = [];
    for (let i = 0; i < sensorValues.length; i++) {        
        let xyData = {};
        xyData.y = sensorValues[i].temperature;
        xyData.x = sensorValues[i].time;
        xyData.temperature = sensorValues[i].temperature;
        xyData.voltage = sensorValues[i].voltage;
        chartAxesData.push(xyData);
    }

    let maxTemperatureValue = 0;
    let annotations = [];
    let maxY = Math.max(...chartAxesData.map(c => c.y)) + 10;
    if(sensorValues[0].maxTemperatureValue != null){
        maxTemperatureValue = sensorValues[0].maxTemperatureValue;
        annotations = getChartAnnotations(maxTemperatureValue, '#ff7e25', 'Max Temp: ' + maxTemperatureValue + '\u2103');

        maxY = Math.round(Math.max(maxY, parseFloat(maxTemperatureValue)) + 10);
    }   

    const sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: 'A' + sensorValues[0].axleWheel.slice(0, 1) + '-T' + sensorValues[0].axleWheel.slice(1, 2),
                backgroundColor: '#075fa5',
                fill: false,
                borderColor: '#075fa5',
                data: chartAxesData,
                borderWidth: 0.5,
                radius: 0.25,
                hitRadius: 2.5,
                hoverRaius: 2.5,
                tension: 0.5,
                pointHoverRadius: 2.5
            }]
        },
        //plugins: [tooltipLine, clickablePoints],
        options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                y: {
                    beginAtZero: true,
                    max: maxY,
                    ticks: {
                        stepSize: 0.5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            hour: 'DD MMM',
                            day: 'DD MMM'
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                },
            },
            plugins: {
                //     tooltip: {                  
                //         callbacks: {
                //             label: (context) => {
                //               return context.dataset.label;
                //             },
                //             afterBody: (context) => {
                //                 const xtraData = [];
                //                 const temperature = context[0].raw.temperature.toString();
                //                 const voltage = context[0].raw.voltage.toString();
                //                 xtraData.push(context[0].label); 
                //                 xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar - ${temperature} \u2103 - ${voltage} V`);
                //                 return xtraData;
                //             }
                //     },
                // },
                zoom: {
                    zoom: {
                        wheel: {
                            enabled: true,                        
                        },
                        mode: "xy",
                    }
                },
                annotation: {
                    annotations: annotations
                },
            },
            layout: {
                padding: {
                    bottom: 25,
                },
            },
        },
    }

    gTemperatureChart = new Chart(chartContext, sensorValuesChartData);
    return chartDiv;
}

function createVoltageChart(sensorValues) {

    const chartDiv = document.createElement('div');
    chartDiv.id = 'voltagechart';
    const chartCanvas = document.createElement('canvas');
    chartCanvas.id = 'voltagechartcanvas';
    chartDiv.appendChild(chartCanvas); 

    const chartContext = chartCanvas.getContext('2d');

    sensorValues = sensorValues.filter((value, index) =>
        index % 5 === 0
    );

    let chartAxesData = [];

    for (let i = 0; i < sensorValues.length; i++) {        
        let xyData = {};
        xyData.y = sensorValues[i].voltage;
        xyData.x = sensorValues[i].time;
        xyData.temperature = sensorValues[i].temperature;
        xyData.voltage = sensorValues[i].voltage;
        chartAxesData.push(xyData);
    }

    let minVoltageValue = 0;
    let annotations =[];
    let maxY = Math.max(...chartAxesData.map(c => c.y)) + 2;
    if(sensorValues[0].minVoltageValue != null){
        minVoltageValue = sensorValues[0].minVoltageValue;
        annotations = getChartAnnotations(minVoltageValue, '#ffce00', 'Min Volt: ' + minVoltageValue + 'V');

        maxY = Math.round(Math.max(maxY, parseFloat(minVoltageValue)));
    }

    const sensorValuesChartData = {
        type: 'line',
        data: {
            datasets: [{
                label: 'A' + sensorValues[0].axleWheel.slice(0, 1) + '-T' + sensorValues[0].axleWheel.slice(1, 2),
                fill: false,
                borderColor: '#075fa5',
                backgroundColor: '#075fa5',
                data: chartAxesData,
                borderWidth: 0.5,
                radius: 0.25,
                hitRadius: 2.5,
                hoverRaius: 2.5,
                tension: 0.5,
                pointHoverRadius: 2.5
            }]
        },
        //plugins: [tooltipLine, clickablePoints],
        options: {
            responsive: true,
            maintainAspectRatio: true,
            scales: {
                y: {
                    beginAtZero: true,
                    max: maxY,
                    ticks: {
                        stepSize: 0.5,
                    }
                },
                x: {
                    scaleLabel: {
                        display: true
                    },
                    type: 'time',
                    time: {
                        unit: 'day',
                        displayFormats: {
                            hour: 'DD MMM',
                            day: 'DD MMM'
                        },
                        tooltipFormat: "DD MMM HH:mm:ss"
                    },
                    position: "bottom"
                },
            },
            plugins: {
            //     tooltip: {                  
            //         callbacks: {
            //             label: (context) => {
            //               return context.dataset.label;
            //             },
            //             afterBody: (context) => {
            //                 const xtraData = [];
            //                 const temperature = context[0].raw.temperature.toString();
            //                 const voltage = context[0].raw.voltage.toString();
            //                 xtraData.push(context[0].label); 
            //                 xtraData.push(`${context[0].dataset.data[context[0].dataIndex].y.toString()} bar - ${temperature} \u2103 - ${voltage} V`);
            //                 return xtraData;
            //             }
            //     },
            // },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,                        
                    },
                    mode: "xy",
                }
            },
            annotation: {
                annotations: annotations
            },
        },
        layout: {
            padding: {
                bottom: 25,
            },
        },

        },
    }

    gVoltageChart = new Chart(chartContext, sensorValuesChartData);
    return chartDiv;
}

const getChartAnnotations = (value, colour, labelContent) => {

    let annotations = [];
    if(value != null){
        annotations = [{
            type: 'line',
            id: 'hline',
            mode: 'horizontal',
            drawTime: 'beforeDatasetsDraw',
            /*scaleID: 'y-axis-0',*/
            yMin: value,
            yMax: value,
            borderColor: colour,
            borderWidth: 1,
            label: {
                display: true,
                padding: {
                    left: 2,
                    right: 2,
                },
                position: 'start',
                backgroundColor: 'white',
                color: colour,
                content: labelContent,
            }
        }];
    }

    return annotations;
}

function createFooterUnitElement(unit, trailerUnit, axle, tyre) {

  const detailPageFooterUnitDiv = document.getElementById('detailpageunit');
  while (detailPageFooterUnitDiv.hasChildNodes()) {
      detailPageFooterUnitDiv.removeChild(detailPageFooterUnitDiv.lastChild);
  }
  const unitClasses = ['d-flex', 'justify-content-center', 'align-items-center', 'pt-2'];   
  detailPageFooterUnitDiv.classList.add(...unitClasses);

  const unitLabelSpan = document.createElement('span');
  unitLabelSpan.innerText = "Unit:\xa0";
  unitLabelSpan.className = 'boldtext';

  const unitSpan = document.createElement('span');
  if(trailerUnit.unitName != null)
    unitSpan.innerText = trailerUnit.unitName;  
  else
    unitSpan.innerText = unit.unitName;
  

  const wheelIdLabelSpan = document.createElement('span');
  wheelIdLabelSpan.innerText = "\xa0Wheel Id:\xa0";
  wheelIdLabelSpan.className = 'boldtext';

  const wheelIdSpan = document.createElement('span');
  wheelIdSpan.innerText = `A${axle} - T${tyre}`;  //axleSensors[tyreArrayIndex].tyreName

  detailPageFooterUnitDiv.appendChild(unitLabelSpan);
  detailPageFooterUnitDiv.appendChild(unitSpan);
  detailPageFooterUnitDiv.appendChild(wheelIdLabelSpan);
  detailPageFooterUnitDiv.appendChild(wheelIdSpan);
}
