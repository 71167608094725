import { signOut, onSleepSignOut, getToken } from "../../main.js";
import { logStatus } from "../../main.js";

var tryLoginCount = 0;
var tryLogoutCount = 0;

export const handleError = (error, token, caller) => {
  try {
    console.log(`ERROR ${error}; TOKEN ${token}; CALLER ${caller}`);
    if (caller === "tokenReceived -> session.loginToken") {
      tryLoginCount++
      logStatus(`Errorhandler - try login count ${tryLoginCount}; CALLER ${caller}`);    
      
      if (tryLoginCount < 5) {
        onSleepSignOut();
        getToken();
      } else {
        signOut();
      }
    }

    if (caller === "tokenReceived -> session.logoutToken") {
      tryLogoutCount++
      logStatus(`Errorhandler - try logout count ${tryLogoutCount}; CALLER ${caller}`);     

      if (tryLogoutCount < 5)
        onSleepSignOut();
      else
        signOut();
    }

    if (error === 1003) {
      // logStatus(`ERROR 10003 - ${wialon.core.Errors.getErrorText(error)}; CALLER ${caller}`)
      signOut();    
    }

    if(token){
      onSleepSignOut();
      
    }           
    else {
      signOut();
    }  
  } catch {
    signOut();
  }
}

// function resumeOnline(token) {    
//     try {
//         console.log(`resumeOnline`);
//         waitCursor('maincontainer');
//         getUnitData().catch((error) => {
//             console.log("errorHandlingJS: resumeOnline(getUnitData) - ERROR " + error + ")");
//             tokenReceived(token);            
//         });   
//     } catch (e) {    
//         location.reload(true);
//     }
// }


