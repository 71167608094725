export function onlineConnection() {
    if (navigator.onLine) {
      isReachable(getServerUrl()).then(function(online) {
        if (online) {
            return true;      
        } else {
          console.log('No Connectivity');
          return false; 
        }
      });
    } else {
      // handle offline status - clear intervalId
      // if (gIntervalId) {
      //     clearInterval(gIntervalId);
      //     console.log(`CLEAR Interval Id: ${gIntervalId}`);
      // }
      console.log('OFFLINE');
      return false;
    }
    return true;
  }
  
  async function isReachable(url) {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     **/
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(function(response) {
        return response && (response.ok || response.type === 'opaque');
      })
      .catch(function(error) {
        console.warn('[Connection test failure]:', error);
      });
  }
  
  function getServerUrl() {
    return window.location.origin;
  }