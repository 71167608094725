export const waitCursor = (element) => {

    const component = document.getElementById(element);              
    while (component.hasChildNodes()) {
        component.removeChild(component.lastChild);
    }

    const fragment = document.createDocumentFragment();

    const waitCursorDiv = document.createElement('div');
    waitCursorDiv.id = 'waitcursor';

    // const labelDiv = document.createElement('div');
    // labelDiv.innerText="Loading Data..."

    const animationDiv = document.createElement('div');
    animationDiv.className = 'infinity-wait';   
      
    waitCursorDiv.appendChild(animationDiv);
    fragment.appendChild(waitCursorDiv);

    component.appendChild(fragment);
}

