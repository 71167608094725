import globals from '../utils/globals.js';
import { isObjectEmpty } from '../utils/general.js';

import { handleError } from '../utils/errorhandler.js';

import {getResource, getResourceNotifications} from './getresourcedata.js';
import {showUnitsListScreen} from '../screens/unitslistscreen.js';
import {createDetailPage} from '../screens/detailpage.js';

export const getUnitData = async function () {
    
    let unitFlags = wialon.util.Number.or(wialon.item.Item.dataFlag.base |
        wialon.item.Unit.dataFlag.sensors |
        wialon.item.Unit.dataFlag.lastMessage);

    let resourceFlags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Resource.dataFlag.trailers);

    const session = wialon.core.Session.getInstance();
    session.loadLibrary("itemIcon");
    session.loadLibrary("unitSensors");
    session.loadLibrary("resourceTrailers");    
    
    session.updateDataFlags(
        [{ type: "type", data: "avl_unit", flags: unitFlags, mode: 0 },
         { type: "type", data: "avl_resource", flags: resourceFlags, mode: 0 }],
        async (error) => {
            if (error) {                
                console.log("getUnitDataJS: getUnitData - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                handleError(error, globals.token, "getUnitData");
            } else {

            const resource = await getResource();

            // console.log('timeMain1: [' + Date.now() + '] ');
            const resourceNotifications = await getResourceNotifications(resource);

            let sensorValueRanges = resourceNotifications.notifications;
            
            let wUnits = session.getItems("avl_unit");
            let wTrailers = resource.getTrailers();

            let unitId = 0;
            let units = [];
            //Create array of Units with trailers and properties
            for (let i = 0; i < wUnits.length; i++) {
                let unit = {};
                let wUnit = wUnits[i];
                unitId = wUnit.getId();
                unit.unitId = unitId;
                //unit.type = wUnit.getType();
                unit.unitIcon = wUnit.getIconUrl(32);
                //unit.unitIcon = wUnit.getIconUrl(16);
                unit.unitName = wUnit.getName();                
                unit.unitMobileNo = wUnit.getPhoneNumber();
                unit.unitPosition = wUnit.getPosition();
                //unit.unitLocation = (await getUnitLocation(unit.unitPosition))[0];
                //if (typeof wUnit.getProfileFields === "function") {
                //    let fields = Object.values(wUnit.getProfileFields());
                //    for (let f = 0; f < fields.length; f++) {
                //        if (fields[f].n === "vehicle_class")
                //            unit.vehicleClass = fields[f].v;
                //        if (fields[f].n === "axles")
                //            unit.axleCount = parseInt(fields[f].v);
                //    }
                //}

                unit.isTrailer = false;
                for (let trl in wTrailers) {
                    let trailer = wTrailers[trl];
                    if (unit.unitName.trim() === trailer.n.trim()) {
                        unit.isTrailer = true;
                        unit.trailerId = trailer.id;
                        //delete wTrailers[trl];
                        break;
                    }
                }

                let sensors = wUnit.getSensors();
                let unitSensors = [];
                for (let s in sensors) {
                    let unitSensor = {};
                    //unitSensor.minPressureValue = 1;
                    //unitSensor.maxPressureValue = 11;
                    //unitSensor.maxTemperatureValue = 85;
                    //unitSensor.minVoltageValue = 3.000;
                    if (sensors[s].n.slice(0, 1) === 'P') {
                        unitSensor.sensorName = sensors[s].n.slice(1);
                        unitSensor.tyreId = sensors[s].d;
                        let sensorAxle = sensors[s].n.slice(1, 2);

                        //const axles = [...new Set(Object.keys(sensors).map(item => sensors[item].n.slice(1, 2)))];
                        
                        for (let valueRange in sensorValueRanges) {
                            if (sensorValueRanges[valueRange].un[0] === unitId) {
                                if (sensorAxle === sensorValueRanges[valueRange].n.slice(sensorValueRanges[valueRange].n.length - 10).substring(0, 1)) {
                                    let sensorMetric = sensorValueRanges[valueRange].n.slice(sensorValueRanges[valueRange].n.length - 11).substring(0, 1);
                                    switch (sensorMetric) {
                                        case 'P':
                                            unitSensor.minPressureValue = sensorValueRanges[valueRange].trg_p.lower_bound;
                                            unitSensor.maxPressureValue = sensorValueRanges[valueRange].trg_p.upper_bound;
                                            //delete sensorValueRanges[valueRange];
                                            break;
                                        case 'T':
                                            unitSensor.maxTemperatureValue = sensorValueRanges[valueRange].trg_p.upper_bound;
                                            //delete sensorValueRanges[valueRange];
                                            break;
                                        case 'V':
                                            unitSensor.minVoltageValue = sensorValueRanges[valueRange].trg_p.lower_bound;
                                            //delete sensorValueRanges[valueRange];
                                            break;
                                    }
                                }                                
                            }                            
                        }
                        unitSensors.push(unitSensor);
                    }
                }

                unit.sensors = unitSensors;

                unit.blueFlags = 0;
                unit.blinkFlags = 0;
                unit.redFlags = 0;
                unit.purpleFlags = 0;
                unit.orangeFlags = 0;
                unit.yellowFlags = 0;
                units.push(unit);
            }
            
            let timeTo = session.getServerTime();
            let timeFrom = timeTo - 2520;

            let prms = [];
            for (let i = 0; i < units.length; i++) {
                let unitId = units[i].unitId;

                let prmsObj = {
                    "svc": "messages/load_interval",
                    "params": {
                        "itemId": unitId,
                        "timeFrom": timeFrom,
                        "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": 3024
                    }
                };
                prms.push(prmsObj);
            }

            let sensorValues = await loadSensorValuesBatch(prms);
            
            for (let u = 0; u < units.length; u++) {
                //let unitId = units[u].unitId;

                for (let i = 0; i < sensorValues[u].messages.length; i++) { // get last received message with values
                    if (sensorValues[u].messages[i].p.data_type === "E6") { // E6 = custom sensor values??

                        for (let property in sensorValues[u].messages[i].p) {
                            if (property === 'data_type' || property === 'real_count' || property === 'total_count') continue;
                            for (let k = 0; k < units[u].sensors.length; k++) {
                                if (property.substring(1, 3) === units[u].sensors[k].sensorName.substring(0, 2)) {
                                    if (property.includes("pressure")) {
                                        units[u].sensors[k].pressureValue = (parseFloat(sensorValues[u].messages[i].p[property]) * 0.0689475729).toFixed(2);
                                    } else {
                                        units[u].sensors[k].temperatureValue = parseInt(sensorValues[u].messages[i].p[property]);
                                    }
                                    if (property.includes("volt")) {
                                        units[u].sensors[k].voltageValue = parseFloat(sensorValues[u].messages[i].p[property]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
            units = getAllUnitsSensorValueFlags(units); 
            let unitLinkedTrailers = [];
            let trailers = wTrailers;
            for (let i = 0; i < units.length; i++) {
                let uId = units[i].unitId;
                //All trailers that are linked/binded
                for (let trl in trailers) {
                    let trailer = trailers[trl];
                    //if (trailer.id !== gSelectedUnit.trailerId ) { //Delink workaround - trailer.bu not updated yet in callback
                    if (uId === trailer.bu) {
                        let unitTrailer = {};
                        unitTrailer.unitLinkedToId = uId;
                        unitTrailer.trailerId = trailer.id;
                        unitTrailer.unitName = trailer.n;
                        //unitTrailer.linkedOrder = parseInt(trailer.c);
                        unitLinkedTrailers.push(unitTrailer);
                        break;
                    }
                }
            }

            for (let i = 0; i < units.length; i++) {
                let unitTrailersFound = [];
                for (let t = 0; t < unitLinkedTrailers.length; t++) {
                    let uTrailer = unitLinkedTrailers[t];
                    if (uTrailer.unitName.trim() === units[i].unitName.trim()) {
                        uTrailer.unitId = units[i].unitId;
                        uTrailer.unitIcon = units[i].unitIcon;
                        uTrailer.unitMobileNo = units[i].unitMobileNo;
                        uTrailer.sensors = units[i].sensors;
                    }
                    if (uTrailer.unitLinkedToId === units[i].unitId) {
                        unitTrailersFound.push(uTrailer);
                    }
                }

                units[i].trailers = unitTrailersFound;
            }
            const unitData = joinTruckAndTrailers(units);
            
            main(unitData).catch((error) => {
                if (error.type === 'API_ERROR') {
                    const reason = error.fullError && error.fullError.reason;
                    console.log("getunitdataJS: initialiseUnits(main) - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error)) + ")";
                } else {
                    console.log("getunitdataJS: initialiseUnits(main) - ERROR " + error + ")");
                    console.error(error);
                }
                handleError(error, globals.token, "getUnitData => main");
            });   
        }
        }
    );
}

async function main(unitData) {

    try {

        document.getElementById('splashcontainer').style.display = 'none';
        document.getElementById('header').style.display = 'block';
        document.getElementById('footer').style.display = 'block';     
        
        if (isObjectEmpty(globals.selectedUnit) || isObjectEmpty(globals.selectedUnit) == null ) {
            showUnitsListScreen(unitData);            
        } else {
            for (let i = 0; i < unitData.length; i++) {
                if (globals.selectedUnit.unitId === unitData[i].unitId) {
                    globals.selectedUnit = unitData[i];
                    localStorage.setItem("uPressureTrack", JSON.stringify(unitData[i]));
                    createDetailPage(unitData, unitData[i]);
                    break;
                }
            }            
        }
    } catch (e) {
        console.log('getunitdataJS: main ERROR: ', e);
        handleError(e, globals.token, "main");
    }
}

function loadSensorValuesBatch(prms) {

    let remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('core/batch', prms,
            (error, result) => {
                if (error) {
                    reject({ type: 'API_ERROR', code: error, fullError: result });
                    return;
                }
                resolve(result);
            },
        );
    });
}

function getAllUnitsSensorValueFlags(units) {    

    for (let u = 0; u < units.length; u++) {
        let purpleflags = 0, blinkflags = 0, redflags = 0, orangeflags = 0, yellowflags = 0, blueflags = 0;
        for (let s = 0; s < units[u].sensors.length; s++) {
            if (parseFloat(units[u].sensors[s].pressureValue) > parseFloat(units[u].sensors[s].maxPressureValue))
                purpleflags++;
            if (units[u].sensors[s].sensorName.slice(0, 1) !== "9" && parseFloat(units[u].sensors[s].pressureValue) < parseFloat(units[u].sensors[s].manufacturersRecommendedPressure) * 20 / 100)
                blinkflags++;
            if (parseFloat(units[u].sensors[s].pressureValue) < parseFloat(units[u].sensors[s].minPressureValue))
                redflags++;
            if (parseFloat(units[u].sensors[s].temperatureValue) > parseFloat(units[u].sensors[s].maxTemperatureValue))
                orangeflags++;
            if (parseFloat(units[u].sensors[s].voltageValue) < parseFloat(units[u].sensors[s].minVoltageValue))
                yellowflags++;
            if (units[u].sensors[s].pressureValue == null && units[u].sensors[s].temperatureValue == null && units[u].sensors[s].voltageValue == null)
                blueflags++;
        }
        units[u].blueFlags = blueflags;
        units[u].purpleFlags = purpleflags;
        units[u].blinkFlags = blinkflags;
        units[u].redFlags = redflags;
        units[u].orangeFlags = orangeflags;
        units[u].yellowFlags = yellowflags;
    }

    return units;
}

function joinTruckAndTrailers(units) {

    let unitWithTrailers = [...units];
    let unitsToRemove = [];

    for (let i = 0; i < unitWithTrailers.length; i++) {

        unitWithTrailers[i].linkedblueFlags = unitWithTrailers[i].blueFlags;
        unitWithTrailers[i].linkedpurpleFlags = unitWithTrailers[i].purpleFlags;
        unitWithTrailers[i].linkedblinkFlags = unitWithTrailers[i].blinkFlags;
        unitWithTrailers[i].linkedredFlags = unitWithTrailers[i].redFlags;
        unitWithTrailers[i].linkedorangeFlags = unitWithTrailers[i].orangeFlags;
        unitWithTrailers[i].linkedyellowFlags = unitWithTrailers[i].yellowFlags;

        //currently only ONE trailer per unit
        if (unitWithTrailers[i].trailers.length > 0) {
            for (let j = 0; j < units.length; j++) {
                for (let t = 0; t < unitWithTrailers[i].trailers.length; t++) {
                    if (unitWithTrailers[i].trailers[t].unitId === units[j].unitId) {

                        unitWithTrailers[i].linkedblueFlags = unitWithTrailers[i].linkedblueFlags + units[j].blueFlags;
                        unitWithTrailers[i].linkedpurpleFlags = unitWithTrailers[i].linkedpurpleFlags + units[j].purpleFlags;
                        unitWithTrailers[i].linkedblinkFlags = unitWithTrailers[i].linkedblinkFlags + units[j].blinkFlags;
                        unitWithTrailers[i].linkedredFlags = unitWithTrailers[i].linkedredFlags + units[j].redFlags;
                        unitWithTrailers[i].linkedorangeFlags = unitWithTrailers[i].linkedorangeFlags + units[j].orangeFlags;
                        unitWithTrailers[i].linkedyellowFlags = unitWithTrailers[i].linkedyellowFlags + units[j].yellowFlags;
                        unitsToRemove.push(units[j].unitId);
                    }
                }
            }
        }
    }

    for (let i = 0; i < unitWithTrailers.length; i++) {
        for (let j = 0; j < unitsToRemove.length; j++) {
            if (unitWithTrailers[i] && (unitWithTrailers[i].unitId === unitsToRemove[j])) {
                unitWithTrailers.splice(i, 1); i--;
            }
        }
    }

    unitWithTrailers.sort(function (a, b) {
        if (a.unitName > b.unitName) return 1;
        if (a.unitName < b.unitName) return -1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedblueFlags > b.linkedblueFlags) return -1;
        if (a.linkedblueFlags < b.linkedblueFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedyellowFlags > b.linkedyellowFlags) return -1;
        if (a.linkedyellowFlags < b.linkedyellowFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedorangeFlags > b.linkedorangeFlags) return -1;
        if (a.linkedorangeFlags < b.linkedorangeFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedpurpleFlags > b.linkedpurpleFlags) return -1;
        if (a.linkedpurpleFlags < b.linkedpurpleFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedredFlags > b.linkedredFlags) return -1;
        if (a.linkedredFlags < b.linkedredFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedblinkFlags > b.linkedblinkFlags) return -1;
        if (a.linkedblinkFlags < b.linkedblinkFlags) return 1;
        return 0;
    });

    return unitWithTrailers;
}
