import globals from '../utils/globals.js';

import { handleError } from "../utils/errorhandler.js";

//var session = wialon.core.Session.getInstance();

export const getResource = async function () {
    try {
        const session = wialon.core.Session.getInstance();
        const flags = wialon.item.Item.dataFlag.base;
        let resource = {};
        return resource = await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_resource", flags: flags, mode: 1 }], 
                (error) => {
                    if (error) {
                        console.log("getresourcedataJS: getResource - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        const user = session.getCurrUser(); 
                        console.log("getresourcedataJS: getResource - API ERROR " + error + " USER: " + user );
                        handleError(error, globals.token, "getResource");
                        //reject({ type: 'PT_API_ERROR', error: error });
                    }

                    let resources = session.getItems("avl_resource");
                    if (!resources || !resources.length) {
                        console.log("getresourcedataJS: getResource - No resources found");
                        handleError(0, globals.token, "getResource");
                    }

                    for (let i = 0; i < resources.length; i++) 
                    {
                        resource = resources[i];                     
                    }
                    resolve(resource);
                });
        });
    } catch (e) {
        handleError(e, globals.token, "getResource");
    }
}

export const getResourceNotifications = async function (resource) {

    try {

        const session = wialon.core.Session.getInstance();
        session.loadLibrary("resourceNotifications");
        
        const flags = window.wialon.item.Item.dataFlag.base | window.wialon.item.Resource.dataFlag.base | window.wialon.item.Item.dataFlag.messages | window.wialon.item.Resource.dataFlag.notifications;
        
        const resourceId = resource.getId();
        //let result = [];
        return await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_resource", flags: flags, mode: 1 }],
                (error) => {
                    if (error) {
                        console.log("notificationsJS: getResourceNotifications - API ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        handleError(error, globals.token, "getResourceNotifications");
                        //reject({ type: API_ERROR, error: error });
                        return;
                    }
                    let resourceNotifications = { resourceId: resourceId, resourceName: resource.getName(), notifications: resource.getNotifications() };
                    resolve(resourceNotifications);
                },
            );
        });
    } catch (e) {
        console.error('notificationsJS: getResourceNotifications - ERROR: ', e);
        handleError(e, globals.token, "getResourceNotifications");
    }
}