import { formatDateTime, convertUnixTime } from "../../utils/timeformats.js";

export const createHeaderMain = (unit) => {

    const headerMain = document.createElement('div');
    headerMain.id = 'headermain';

    const unitHeader = createUnitElement(unit);  
    const lastUpdatedHeader = createLastUpdatedElement(unit);      

    headerMain.appendChild(unitHeader);    
    headerMain.appendChild(lastUpdatedHeader); 
    
    return headerMain;
}

export const createHeaderExitNav = () => {

    const exitNav = document.createElement('div');
    exitNav.id = 'headerexitnav';
    //exitNav.dataset.target = "#signout";
    exitNav.setAttribute('data-bs-target','#signOutModal')
    exitNav.setAttribute('data-bs-toggle','modal')

    const exitSvg = document.createElement('svg');   
    exitSvg.className = 'imgbutton buttonexit'; 
    exitNav.appendChild(exitSvg);

    return exitNav;
}

const createUnitElement = (unit) => {

    const unitDiv= document.createElement('div');
    unitDiv.id = 'detailpageunit'
    const unitClasses = ['d-flex', 'justify-content-center', 'align-items-center', 'pt-2'];   
    unitDiv.classList.add(...unitClasses);

    const unitSpan = document.createElement('span');
    unitSpan.className = 'boldtext';
    const unitName = unit.unitName;
    unitSpan.innerText = unitName;
    let trailerName = '';
    let stringLink = '\xa0-o-\xa0';
    if (unit.trailers != null && unit.trailers.length > 0) {
        for (let t = 0; t < unit.trailers.length; t++) {
            trailerName = trailerName + stringLink + unit.trailers[t].unitName 
        }
        unitSpan.innerText = unitName + trailerName;
    } else {
        unitSpan.innerText = unitName;
    }

    unitDiv.appendChild(unitSpan);
    
    return unitDiv
}

export const createLastUpdatedElement = (unit) => {

    const lastUpdatedDiv = document.createElement('div');
    const lastUpdatedClasses = ['d-flex', 'justify-content-center', 'align-items-center', 'py-1', 'flex-nowrap'];   
    lastUpdatedDiv.classList.add(...lastUpdatedClasses);

    const timerSvg = document.createElement('svg');   
    timerSvg.className = 'buttontimer'; ;

    const lastUpdatedLabelSpan = document.createElement('span');
    lastUpdatedLabelSpan.className = 'lastupdated lastupdatedlabel';
    lastUpdatedLabelSpan.innerText = "Last updated:\xa0";

    const session = wialon.core.Session.getInstance();
    const wUnit = session.getItem(unit.unitId);
    const unitPosition = wUnit.getPosition();
    const lastUpdatedSpan = document.createElement('span');
    lastUpdatedSpan.className = 'lastupdated';
    lastUpdatedSpan.innerText = formatDateTime(convertUnixTime(unitPosition.t));

    lastUpdatedDiv.appendChild(timerSvg);
    lastUpdatedDiv.appendChild(lastUpdatedLabelSpan);
    lastUpdatedDiv.appendChild(lastUpdatedSpan);

    return lastUpdatedDiv;
}