import globals from '../utils/globals.js';

import { handleError } from '../utils/errorhandler.js';
import {convertUnixTime} from '../utils/timeformats.js';

export async function loadSensorValuesMessages(unit, axleSensor) {
     
    // if (!session) session = wialon.core.Session.getInstance();
    const session = wialon.core.Session.getInstance();
    const timeTo = session.getServerTime();
    //let timeFrom = timeTo - 3600 * 24 * 30;
    const timeFrom = timeTo - 3600 * 24 * 14;
    const msgLoadCount = 0xffffffff; //0xffffffff=4294967295 All messages

    const prms = [];
    const unitId = unit.unitId;
    const prmsObj = {
        "svc": "messages/load_interval",
        "params": {
            "itemId": unitId,
            "timeFrom": timeFrom,
            "timeTo": timeTo, "flags": 32, "flagsMask": 0, "loadCount": msgLoadCount
        }
    };
    prms.push(prmsObj);
    let data = await loadSensorValuesBatch(prms);

    return await new Promise((resolve, reject) => {

        let sensorValues = [];
        for (let p = 0; p < prms.length; p++) {
            for (let i = 0; i < data[p].messages.length; i++) {
                if (data[p].messages[i].p.data_type === "E6") {

                    for (let property in data[p].messages[i].p) {
                        if (property === 'data_type' || property === 'real_count' || property === 'total_count') continue;                 
                        if (property.includes("pressure")) {
                            if (property.slice(1, 3) === axleSensor.sensorName.slice(0, 2)) {
                                let sensorValue = {};
                                sensorValue.unitId = prms[p].params.itemId;
                                sensorValue.axleWheel = property.slice(1, 3);
                                sensorValue.minPressureValue = axleSensor.minPressureValue;
                                sensorValue.maxPressureValue = axleSensor.maxPressureValue;
                                sensorValue.maxTemperatureValue = axleSensor.maxTemperatureValue;
                                sensorValue.minVoltageValue = axleSensor.minVoltageValue; 
                                sensorValue.unixTime = data[p].messages[i].t;
                                sensorValue.time = convertUnixTime(data[p].messages[i].t);
                                sensorValue.posx = data[p].messages[i].pos?.x || 0;
                                sensorValue.posy = data[p].messages[i].pos?.y || 0;
                                sensorValue.pressure = parseFloat((data[p].messages[i].p[property] * 0.0689475729).toFixed(2));
                                sensorValue.temperature = data[p].messages[i].p["t" + property.substring(1, 3) + "_temp"];
                                sensorValue.voltage = data[p].messages[i].p["t" + property.substring(1, 3) + "_sensor_volt"];
                                sensorValues.push(sensorValue);
                            }
                        }
                    }
                }
            }
        }
        if (sensorValues.length) {
            sensorValues.sort((a, b) => a.unitId - b.unitId || a.axleWheel - b.axleWheel || a.unixTime - b.unixTime);     
            resolve(sensorValues);
        } else {            
            reject(new Error(`No data found (Sensor A${axleSensor.sensorName.slice(0,1)}-T${axleSensor.sensorName.slice(1,2)})`));
        }
    }).catch(alert);
}

function loadSensorValuesBatch(prms) {

    const remote = wialon.core.Remote.getInstance();

    return new Promise((resolve, reject) => {
        remote.remoteCall('core/batch', prms,
            (error, result) => {
                if (error) {
                    // reject({ type: 'API_ERROR', code: error, fullError: `getSensorDataJS - loadSensorValuesBatch: ${error}`});
                    // return;
                    handleError(error, globals.token, "loadSensorValuesBatch")
                }
                resolve(result);
            },
        );
    });
}