import '../../scss/main.scss';

import globals from '../utils/globals.js';

import { handleError } from '../utils/errorhandler.js';

import { waitCursor } from '../screens/components/waitcursor.js'

import {getResource, getResourceNotifications} from '../data/getresourcedata.js'
import {formatDateTime, convertUnixTime} from '../utils/timeformats.js';
import {getAxleTyreProperties, getTyreArrayIndex} from '../utils/unitinfo.js';
import {
    createUnitElement,
    createTyreNameElement, 
    createSensorIdElement, 
    createSensorPressureElement, 
    createSensorTemperatureElement, 
    createSensorVoltageElement, 
    createSensorNoSignalElement } from './components/unitdiagrams.js';

import { showWheelReport } from '../screens/wheelreports.js'; 

import {loadSensorValuesMessages} from '../data/getSensorData.js';

export const showDiagramScreen = (unitData) => {    

    const wheelsDiagramScreen = document.getElementById('maincontainer');
    //wheelsDiagramScreen.scrollIntoView();
    wheelsDiagramScreen.className = 'maincontainer';
    while (wheelsDiagramScreen.hasChildNodes()) {
        wheelsDiagramScreen.removeChild(wheelsDiagramScreen.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();
   
    let diagramDiv = document.createElement('div');
    diagramDiv.id = 'wheelsdiagram';
    //diagramDiv.className = 'maincontainer';     
    
    diagramFragment.appendChild(diagramDiv);   
    wheelsDiagramScreen.appendChild(diagramFragment);

    // const diagramWaitCursorDiv = document.createElement('div');
    // diagramWaitCursorDiv.id = 'diagramwaitcursor';
    // diagramDiv.appendChild(diagramWaitCursorDiv);

    getDiagramConfiguration(unitData, diagramDiv);
}

function getLinkedTrailers(unit, unitDiagramDiv) {

    for (let t = 0; t < unit.trailers.length; t++) {
        let trailerDiv = createUnitElement();
        unitDiagramDiv.appendChild(trailerDiv);

        unit.trailers[t] = getAxleTyreProperties(unit.trailers[t]);

        let trailerAxleMin = 0;
        let trailerAxleMax = 0;
        let trailerAxles = [];
        for (let ts = 0; ts < unit.trailers[t].sensors.length; ts++) {
            if (parseInt(unit.trailers[t].sensors[ts].sensorName.slice(0, 1)) !== 9)
                trailerAxles.push(parseInt(unit.trailers[t].sensors[ts].sensorName.slice(0, 1)));
        }

        trailerAxleMin = Math.min.apply(this, trailerAxles);
        trailerAxleMax = Math.max.apply(this, trailerAxles);
        for (let ta = trailerAxleMin; ta <= trailerAxleMax; ta++) {

            let axleSensors = unit.trailers[t].sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === ta);
            let tyreAxleDiv = createTyreAxleElements(unit.trailers[t], ta, axleSensors);

            trailerDiv.appendChild(tyreAxleDiv);
        }

        let trailerAxleSpareSensors = unit.trailers[t].sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === 9);

        let tyreSpareAxleDiv = createSpareTyreAxleElements(unit.trailers[t], trailerAxleSpareSensors);
        trailerDiv.appendChild(tyreSpareAxleDiv);
    }

    return unitDiagramDiv;
}

function getDiagramConfiguration(unit, diagramDiv) {
    
    let unitDiv = createUnitElement();
    diagramDiv.appendChild(unitDiv);

    unit = getAxleTyreProperties(unit);

    let unitAxleMin = 0;
    let unitAxleMax = 0;
    let unitAxles = [];
    for (let ts = 0; ts < unit.sensors.length; ts++) {
        if (parseInt(unit.sensors[ts].sensorName.slice(0, 1)) !== 9)
            unitAxles.push(parseInt(unit.sensors[ts].sensorName.slice(0, 1)));
    }

    // const resource = await getResource();
    // const resourceNotifications = await getResourceNotifications(resource);

    unitAxleMin = Math.min.apply(this, unitAxles);
    unitAxleMax = Math.max.apply(this, unitAxles);
    for (let ta = unitAxleMin; ta <= unitAxleMax; ta++) {

        let axleSensors = unit.sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === ta);
        let tyreAxleDiv = createTyreAxleElements(unit, ta, axleSensors);

        unitDiv.appendChild(tyreAxleDiv);
    }

    let unitAxleSpareSensors = unit.sensors.filter(s => parseInt(s.sensorName.slice(0, 1)) === 9);

    let tyreSpareAxleDiv = createSpareTyreAxleElements(unit, unitAxleSpareSensors);
    unitDiv.appendChild(tyreSpareAxleDiv);

    if (unit.trailers.length > 0) {
        diagramDiv = getLinkedTrailers(unit, diagramDiv);
    }
}

function createTyreAxleElements(unit, axle, axleSensors) {

    let tyreAxleDiv = document.createElement('div');
    tyreAxleDiv.className = 'wheelaxle';   

    if (axleSensors.length <= 2) {
        let tyre1Div = createTyreElement(unit, axle, 1, axleSensors);
        tyreAxleDiv.appendChild(tyre1Div);

        const axledivclasses = ['supersinglewheelaxle'];

        let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
        tyreAxleDiv.appendChild(axleDiv);
        let tyre4Div = createTyreElement(unit, axle, 4, axleSensors);
        tyreAxleDiv.appendChild(tyre4Div);

    } else {
        for (let s = 1; s <= 2; s++) {


            const tyreDiv = createTyreElement(unit, axle, s, axleSensors);
            tyreAxleDiv.appendChild(tyreDiv);
        }

        const axledivclasses = ['axle'];

        let axleDiv = createAxleElement(unit, axle, axleSensors, axledivclasses);
        tyreAxleDiv.appendChild(axleDiv);

        for (let s = 3; s <= 4; s++) {
            let tyreDiv = createTyreElement(unit, axle, s, axleSensors);

            tyreAxleDiv.appendChild(tyreDiv);
        }
    }
    return tyreAxleDiv;
}

function createSpareTyreAxleElements(unit, axleSensors) {

    let tyreSpareAxleDiv = document.createElement('div');
    tyreSpareAxleDiv.className = 'wheelaxle';

    if (axleSensors.length > 0) {

        for (let st = 0; st < axleSensors.length; st++) {
            console.log(axleSensors[st].sensorName);
            let spare = parseInt(axleSensors[st].sensorName.slice(1, 2));
            let tyreDiv = createTyreElement(unit, 9, spare, axleSensors); 
            console.log('tyrediv')
            tyreSpareAxleDiv.appendChild(tyreDiv);
        }
    } 
    return tyreSpareAxleDiv;
}

function createAxleElement(unit, axle, axleSensors, axledivclasses) {

    const axleDiv = document.createElement('div');
    const axleSvg = document.createElement('svg');   
    axleSvg.className = 'shortaxle'; 

    axleDiv.appendChild(axleSvg);

    // const axleArmLeftDiv = document.createElement('div');
    // axleArmLeftDiv.classList.add(...axledivclasses);
    // const axleDougnutDiv = document.createElement('div');
    // axleDougnutDiv.className = 'doughnut';
    // const axleArmRightDiv = document.createElement('div');
    // axleArmRightDiv.classList.add(...axledivclasses);
    // axleDiv.appendChild(axleArmLeftDiv);
    // axleDiv.appendChild(axleDougnutDiv);
    // axleDiv.appendChild(axleArmRightDiv);

    return axleDiv;
}

function getAxleActiveStatus(axleStatusNotifications, unit, axle) {

    let notifications = axleStatusNotifications.notifications;
    for (let key in notifications) {
        if (!notifications.hasOwnProperty(key)) continue;
        let obj = notifications[key];
        if (obj.n.substring(0, obj.n.length - 9) === unit.unitName + "-P" + axle) {
            return true;
        }
    }
    return false;
}

function createTyreIdElement(unit, axle, tyre, axleSensors) {

    const tyreIdDiv = document.createElement('div');
    tyreIdDiv.className = 'tyreid';
    if (parseInt(axle) === 9) tyreIdDiv.innerText = `S-${tyre}`;
    else tyreIdDiv.innerText = `A${axle}-T${tyre}`;
    // let tyreArrayIndex = 0;  
    // if (axleSensors.length > 0){
    //     tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors); 
    //     tyreIdDiv.id = 'wheel' + axleSensors[0].sensorName.slice(0, 1) + tyre;

    //     let tyredivclasses = ['wheeldefault'];  

    //     let sensorType = 'wheelcorrect';
    //     if (axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].hasOwnProperty('sensorType')
    //         && axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType !== "") {
    //         sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType;
    //     }


    //         tyredivclasses = ['wheel', sensorType];  

          
    //         tyreIdDiv.classList.add(...tyredivclasses);
    // }


    return tyreIdDiv;
}

function createTyreElement(unit, axle, tyre, axleSensors) {

    const wheel = document.createElement('div');

    const tyreIdDiv = createTyreIdElement(unit, axle, tyre, axleSensors);

    let tyreDiv = document.createElement('div');
    let tyreArrayIndex = 0;  
    if (axleSensors.length > 0){
        tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors); 
        tyreDiv.id = 'wheel' + axleSensors[0].sensorName.slice(0, 1) + tyre;

        let tyredivclasses = ['wheeldefault'];  

        let sensorType = 'wheelcorrect';
        if (axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].hasOwnProperty('sensorType')
            && axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType !== "") {
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType;
        }

        if (globals.currentPage === "SENSORVALUESDIAGRAM"){

            let sensorPressureDiv = {};
            let sensorTemperatureDiv = {};
            let sensorVoltageDiv = {};                      
        
            if (sensorType !== "wheelblue") {
                sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
                sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
                sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);

                tyreDiv.appendChild(sensorPressureDiv);
                tyreDiv.appendChild(sensorTemperatureDiv);
                tyreDiv.appendChild(sensorVoltageDiv);

            } else {
                tyredivclasses = ['wheelnosignal'];
                tyreDiv.innerText= 'No Signal';
            }
        } else {
            tyredivclasses = ['wheel', sensorType];  
        }
          
        tyreDiv.classList.add(...tyredivclasses);
    }

    ////
    tyreDiv.onclick = async function () {
        waitCursor('appstatus');   

        const sensorValues = await loadSensorValuesMessages(unit, axleSensors[tyreArrayIndex]);    

        if (sensorValues == null) {
            let diagramWaitCursor = document.getElementById('diagramwaitcursor');
            while (diagramWaitCursor.firstChild) {
                diagramWaitCursor.removeChild(diagramWaitCursor.firstChild);
            }
        }
        else {
            showWheelReport(unit, axle, tyre, sensorValues); 
            // let trailerUnit = unit;
            // if(unit.trailerId == null)
            //     showWheelReport(unit, axle, tyre, sensorValues); 
            // else{

            //     const unitLinkedToId = trailerUnit.unitLinkedToId
            //     unit = {};

            //     for (let u = 0; u < units.length; u++) {
            //         if (units[u].unitId === unitLinkedToId){
            //         unit = units[u];
            //         break;
            //         }
            //     }

            //     showWheelReport(unit, axle, tyre, sensorValues); 
            // }
        }
    };

    ///


    wheel.appendChild(tyreIdDiv);
    wheel.appendChild(tyreDiv);

    return wheel;
}

function createAxleInfoElement(unit, axle) {

    let mrpValue = 0;
    let pdaValue = 0;
    let minPressureValue = 0;
    let maxPressureValue = 0;
    let maxTempValue = 0;
    let minVoltageValue = 0;

    for (let s = 0; s <= unit.sensors.length; s++) {
        if (axle === parseInt(unit.sensors[s].sensorName.slice(0, 1))) {
            mrpValue = unit.sensors[s].manufacturersRecommendedPressure;
            pdaValue = unit.sensors[s].percentageDeviationAllowed;
            minPressureValue = unit.sensors[s].minPressureValue;
            maxPressureValue = unit.sensors[s].maxPressureValue;
            maxTempValue = unit.sensors[s].maxTemperatureValue;
            minVoltageValue = unit.sensors[s].minVoltageValue;
            break;
        }
    }

    let axleinfodivclasses = ['axleinfo', 'axleinfohide'];
    let axleInfoDiv = document.createElement('div');
    axleInfoDiv.classList.add(...axleinfodivclasses);

    let mrpDiv = createManufacturersRecommendedPressureElement(mrpValue);
    axleInfoDiv.appendChild(mrpDiv);

    let pdaDiv = createPercentageDeviationAllowedElement(pdaValue);
    axleInfoDiv.appendChild(pdaDiv);

    let minPressureDiv = createMinPressureValueElement(minPressureValue);
    axleInfoDiv.appendChild(minPressureDiv);

    let maxPressureDiv = createMaxPressureValueElement(maxPressureValue);
    axleInfoDiv.appendChild(maxPressureDiv);

    let maxTempDiv = createMaxTemperatureValueElement(maxTempValue);
    axleInfoDiv.appendChild(maxTempDiv);

    let minVoltageDiv = createMinVoltageValueElement(minVoltageValue);
    axleInfoDiv.appendChild(minVoltageDiv);

    return axleInfoDiv;
}

function createManufacturersRecommendedPressureElement(mrpValue) {

    if (mrpValue == null || mrpValue == "null" || mrpValue == "") mrpValue = "--";

    let mrpDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Rec Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(mrpValue);
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.appendChild(valueSpanText);

    let mrpSpan = document.createElement('span');
    mrpSpan.appendChild(labelSpan);
    mrpSpan.appendChild(valueSpan);

    mrpDiv.appendChild(mrpSpan);

    return mrpDiv;
}

function createPercentageDeviationAllowedElement(pdaValue) {

    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

    let pdaDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Deviation (%): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(pdaValue);
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.appendChild(valueSpanText);

    let pdaSpan = document.createElement('span');
    pdaSpan.appendChild(labelSpan);
    pdaSpan.appendChild(valueSpan);

    pdaDiv.appendChild(pdaSpan);

    return pdaDiv;
}

function createMinPressureValueElement(minPressurevalue) {

    if (minPressurevalue == null || minPressurevalue == "null" || minPressurevalue == "") minPressurevalue = "--";

    let minPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(minPressurevalue);
    valueSpan.className = 'boldtextmaxminvalue pressurered';
    valueSpan.appendChild(valueSpanText);

    let minPressureSpan = document.createElement('span');
    minPressureSpan.appendChild(labelSpan);
    minPressureSpan.appendChild(valueSpan);

    minPressureDiv.appendChild(minPressureSpan);

    return minPressureDiv;
}

function createMaxPressureValueElement(maxPressureValue) {

    if (maxPressureValue == null || maxPressureValue == "null" || maxPressureValue == "") maxPressureValue = "--";

    let maxPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(maxPressureValue); 
    valueSpan.className = 'boldtextmaxminvalue pressurepurple';
    valueSpan.appendChild(valueSpanText);

    let maxPressureSpan = document.createElement('span');
    maxPressureSpan.appendChild(labelSpan);
    maxPressureSpan.appendChild(valueSpan);

    maxPressureDiv.appendChild(maxPressureSpan);

    return maxPressureDiv;
}

function createMaxTemperatureValueElement(maxTemperatureValue) {

    if (maxTemperatureValue == null || maxTemperatureValue == "null" || maxTemperatureValue == "") maxTemperatureValue = "--";

    let maxTemperatureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Temp (°C): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(maxTemperatureValue);
    valueSpan.className = 'boldtextmaxminvalue temperatureorange';
    valueSpan.appendChild(valueSpanText);

    let maxTemperatureSpan = document.createElement('span');
    maxTemperatureSpan.appendChild(labelSpan);
    maxTemperatureSpan.appendChild(valueSpan);

    maxTemperatureDiv.appendChild(maxTemperatureSpan);

    return maxTemperatureDiv;
}

function createMinVoltageValueElement(minVoltageValue) {

    if (minVoltageValue == null || minVoltageValue == "null" || minVoltageValue == "") minVoltageValue = "--";

    let minVoltageDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Voltage (V): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(minVoltageValue);
    valueSpan.className = 'boldtextmaxminvalue voltageyellow';
    valueSpan.appendChild(valueSpanText);

    let minVoltageSpan = document.createElement('span');
    minVoltageSpan.appendChild(labelSpan);
    minVoltageSpan.appendChild(valueSpan);

    minVoltageDiv.appendChild(minVoltageSpan);

    return minVoltageDiv;
}

function calculateMinMaxPressure(textbox) {

    let manufacturersRecommendedPressure = parseFloat(document.getElementById('manufacturersRecommendedPressure').value);
    let percentageDeviationAllowed = parseFloat(document.getElementById('percentageDeviationAllowed').value);
    if (manufacturersRecommendedPressure && percentageDeviationAllowed) {
        document.getElementById("minPressure").value = calculateMinPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
        document.getElementById("maxPressure").value = calculateMaxPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
    }

    return true;
}

function calculateMinPressure(manufacturersRecommendedPressure, percentageDeviationAllowed) {

    let minPressure = (manufacturersRecommendedPressure - (percentageDeviationAllowed / 100 * manufacturersRecommendedPressure));
    return Math.round(minPressure * 100) / 100;
}

function calculateMaxPressure(manufacturersRecommendedPressure, percentageDeviationAllowed) {

    let maxPressure = (manufacturersRecommendedPressure + (percentageDeviationAllowed / 100 * manufacturersRecommendedPressure));
    return Math.round(maxPressure * 100) / 100;
}

async function submitValuesOutOfRangeForm() {

    manufacturersRecommendedPressureValidation();
    percentageDeviationAllowedValidation();

    document.body.style.cursor = 'wait';
    //$('#container').overlayMask();
    document.getElementById("loadingBoxText").innerText = "Updating 'sensor values out of range' data... ";
    $("#loadingBox").modal({
        backdrop: "static", //remove ability to close modal with click
        keyboard: false, //remove option to close with keyboard
        show: true //Display loader!
    });

    let valuesOutOfRangeParams = {};
    valuesOutOfRangeParams.notificationId = document.getElementById('notificationId').value;
    valuesOutOfRangeParams.unitId = document.getElementById('unitId').value;
    if (document.getElementById('unitLinkedToId').value)
        valuesOutOfRangeParams.unitLinkedToId = document.getElementById('unitLinkedToId').value;
    valuesOutOfRangeParams.unitName = document.getElementById("vormUnitName").value;
    let axleName = document.getElementById('axle').value;
    let axle = document.getElementById('axle').value.slice(-1);
    if (axleName === "Spare Axle")
        axle = "9";

    let axleSensorCount = document.getElementById('axleSensorCount').value;
    valuesOutOfRangeParams.axleSensorCount = axleSensorCount;

    let wheels = [];
    if (axleSensorCount === "1") { //only one spare tyre
        valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
        wheels.push(valuesOutOfRangeParams.wheel1);
    }
    if (axleSensorCount === "2") {
        if (axle === "9") {
            valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
            wheels.push(valuesOutOfRangeParams.wheel1);
            valuesOutOfRangeParams.wheel4 = document.getElementById('axleSensorId' + axle + '2').value;
            wheels.push(valuesOutOfRangeParams.wheel4);
        } else {
            valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
            wheels.push(valuesOutOfRangeParams.wheel1);
            valuesOutOfRangeParams.wheel4 = document.getElementById('axleSensorId' + axle + '4').value;
            wheels.push(valuesOutOfRangeParams.wheel4);
        }
    }
    if (axleSensorCount === "4") {
        valuesOutOfRangeParams.wheel1 = document.getElementById('axleSensorId' + axle + '1').value;
        wheels.push(valuesOutOfRangeParams.wheel1);
        valuesOutOfRangeParams.wheel2 = document.getElementById('axleSensorId' + axle + '2').value;
        wheels.push(valuesOutOfRangeParams.wheel2);
        valuesOutOfRangeParams.wheel3 = document.getElementById('axleSensorId' + axle + '3').value;
        wheels.push(valuesOutOfRangeParams.wheel3);
        valuesOutOfRangeParams.wheel4 = document.getElementById('axleSensorId' + axle + '4').value;
        wheels.push(valuesOutOfRangeParams.wheel4);
    }
    valuesOutOfRangeParams.wheels = wheels;
    valuesOutOfRangeParams.axle = axle;

    valuesOutOfRangeParams.manufacturersRecommendedPressure  = parseFloat(document.getElementById("manufacturersRecommendedPressure").value);
    valuesOutOfRangeParams.percentageDeviationAllowed = parseFloat(document.getElementById("percentageDeviationAllowed").value);

    let minPressure = parseFloat(document.getElementById("minPressure").value);
    let maxPressure = parseFloat(document.getElementById("maxPressure").value);

    if (minPressure == null && maxPressure == null) {
        minPressure = 9;
        maxPressure = 12;
    }

    if (minPressure == null && maxPressure) {
        minPressure = maxPressure - 3;
    }

    if (minPressure && maxPressure == null) {
        maxPressure = minPressure + 3;
    }

    valuesOutOfRangeParams.minPressure = minPressure;
    valuesOutOfRangeParams.maxPressure = maxPressure;

    valuesOutOfRangeParams.minTemperature = -18;
    if (document.getElementById('maxTemperature').value)
        valuesOutOfRangeParams.maxTemperature = parseInt(document.getElementById('maxTemperature').value);
    else
        valuesOutOfRangeParams.maxTemperature = 65;

    if (document.getElementById('minVoltage').value)
        valuesOutOfRangeParams.minVoltage = parseFloat(document.getElementById('minVoltage').value);
    else
        valuesOutOfRangeParams.minVoltage = 3.000;
    valuesOutOfRangeParams.maxVoltage = 5;

    await setAxleSensorPressureMetricAttributes(valuesOutOfRangeParams);
    setUnitValuesOutOfRangeNotifications(valuesOutOfRangeParams);

    //document.getElementById("loadingBox").modal("hide");
    //document.body.style.cursor = 'default';
    //$("#loadingBox").modal("hide");
    document.getElementById("valuesoutofrangemodal").style.display = "none"; //valuesoutofrangemodal

    return false;
}

function manufacturersRecommendedPressureValidation() {

    if (parseFloat(document.getElementById('manufacturersRecommendedPressure').value) < 1 || parseFloat(document.getElementById('manufacturersRecommendedPressure').value) > 9)
        document.getElementById('manufacturersRecommendedPressure').setCustomValidity("Please enter a value between 1.0 and 9.0 bar");

    return;
}

function percentageDeviationAllowedValidation() {
    if (parseFloat(document.getElementById('percentageDeviationAllowed').value) < 20 || parseFloat(document.getElementById('percentageDeviationAllowed').value) > 35)
        document.getElementById('percentageDeviationAllowed').setCustomValidity("Please enter a value not less than 20%");

    return;
}

async function setAxleSensorPressureMetricAttributes(valuesOutOfRangeParams) {

    try {

        global.pressureMetricAttributesUpdated = false;

        let session = global.session;
        session.loadLibrary("itemCustomFields");

        let flags = wialon.util.Number.or(wialon.item.Item.dataFlag.base, wialon.item.Item.dataFlag.customFields, wialon.item.Item.dataFlag.adminFields);

        let axleMRPValue = false;
        let axlePPDValue = false;

        return await new Promise((resolve, reject) => {
            session.updateDataFlags(
                [{ type: "type", data: "avl_unit", flags: flags, mode: 0 }],
                (error) => {
                    if (error) {
                        console.log("wheelsdiagramsectionJS: setAxleSensorPressureMetricAttributes - ERROR " + error + " #" + wialon.core.Errors.getErrorText(error));
                        handleError(error, globals.token, "setAxleSensorPressureMetricAttributes");
                    }

                    let unit = session.getItem(valuesOutOfRangeParams.unitId);
                    let customFields = unit.getCustomFields();
                    //cur_unit.updateCustomField({ id: prop_id, n: name, v: value });
                    for (let cf in customFields) {  // construct select list

                        if (customFields[cf].n.trim() === "A" + valuesOutOfRangeParams.axle + "MRP".trim()) {
                            unit.updateCustomField({ id: customFields[cf].id, n: customFields[cf].n, v: valuesOutOfRangeParams.manufacturersRecommendedPressure });
                            //axleSensorPressureMetricAttributes.manufacturersRecommendedPressure = customFields[cf].v;
                            axleMRPValue = true;
                        }

                        if (customFields[cf].n.trim() === "A" + valuesOutOfRangeParams.axle + "PPD".trim()) {
                            unit.updateCustomField({ id: customFields[cf].id, n: customFields[cf].n, v: valuesOutOfRangeParams.percentageDeviationAllowed });
                            axlePPDValue = true;
                        }
                    }

                    if (axleMRPValue && axlePPDValue)
                        resolve(true);
                }
            );
        });
    } catch (e) {
        console.error('wheelsdiagramsectionJS: setAxleSensorPressureMetricAttributes - ERROR: ', e);
        handleError(e, globals.token, "setAxleSensorPressureMetricAttributes");
    }
}