export const createTyreNameElement = (axle, tyre) => {

    let sensorNameDiv = document.createElement('div');
    sensorNameDiv.className = "axletyresensor";
    if (axle === "S")
        sensorNameDiv.innerHTML = "Spare " + tyre;
    else
        sensorNameDiv.innerHTML = "A" + axle + "-T" + tyre;

    return sensorNameDiv;
}

export const createUnitElement = () => {

    let unitDiv = document.createElement('div');
    unitDiv.className = 'unitbox';

    return unitDiv;
}

function createUnitNameElements(unitName) {

    let unitNameHeader = document.createElement('h6');
    let unitNameHeaderNode = document.createTextNode(unitName);
    unitNameHeader.appendChild(unitNameHeaderNode);
   
    return unitNameHeader;
}

export const createTyreIdElement = (tyreArrayIndex, axleSensors) => {

    let tyreId = document.createElement('div');
    tyreId.className = 'tyreid';
    tyreId.innerText = axleSensors[tyreArrayIndex].tyreId;

    return tyreId;
}

export const createSensorIdElement = (sensorId) => {

    let sensorIdDiv = document.createElement('div'); 
    sensorIdDiv.className = 'sensorid';
    sensorIdDiv.innerHTML = sensorId;

    return sensorIdDiv;
}

export const createSensorPressureElement = (tyreArrayIndex, axleSensors) => {
    
    console.log(`${axleSensors[tyreArrayIndex]}`)
    let sensorPressureDiv = document.createElement('div');
    let sensorvaluesdivclasses = ['sensorvalues'];
    
    if (axleSensors[tyreArrayIndex].pressureLabelColour != 'green')
        sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].pressureLabelColour}`];


    sensorPressureDiv.classList.add(...sensorvaluesdivclasses);

    let pressure = axleSensors[tyreArrayIndex].pressureValue; 
    sensorPressureDiv.innerHTML = pressure + " bar";    
    return sensorPressureDiv;
}

export const createSensorTemperatureElement = (tyreArrayIndex, axleSensors) => {
    
    let sensorTemperatureDiv = document.createElement('div');  
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].temperatureLabelColour != 'green')
        sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].temperatureLabelColour}`];

    let temperature = axleSensors[tyreArrayIndex].temperatureValue;
    // if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateTemperatureValue) {
    //     sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateTemperatureLabelColour];
    //     temperature = axleSensors[tyreArrayIndex].selectedDateTemperatureValue;
    // }
    sensorTemperatureDiv.classList.add(...sensorvaluesdivclasses);
    sensorTemperatureDiv.innerHTML = temperature + " &#x2103";    

    return sensorTemperatureDiv;
}

export const createSensorVoltageElement = (tyreArrayIndex, axleSensors) => {
        
    let sensorVoltageDiv = document.createElement('div');    
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].voltageLabelColour != 'green')
        sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].voltageLabelColour}`];

    let voltage = axleSensors[tyreArrayIndex].voltageValue;
    // if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateVoltageValue) {
    //     sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateVoltageLabelColour];
    //     voltage = axleSensors[tyreArrayIndex].selectedDateVoltageValue;
    // }
    sensorVoltageDiv.classList.add(...sensorvaluesdivclasses);
    sensorVoltageDiv.innerHTML = voltage + " V";

    return sensorVoltageDiv;
}

export const createSensorNoSignalElement = (tyreArrayIndex, axleSensors) => {

    let sensorNoSignalDiv = document.createElement('div');
    // let sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].noSignalLabelColour}`];    
    // if (global.sensorValuesDiagramDateSelected) {
    //     sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateNoSignalLabelColour];
    // }
    sensorNoSignalDiv.classList.add(...sensorvaluesdivclasses);
    sensorNoSignalDiv.innerText = "No Signal";

    return sensorNoSignalDiv;
}